/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ProductSelectionAttribute } from "../selectComponents/SelectComponentsTypes";
import LoadSideBarItems from "./LoadSideBarItems";

interface SearchSidebarProps {
  selectionAttributes: ProductSelectionAttribute[];
  selectedAttributes: ProductSelectionAttribute[];
  setSelectedAttributes: (
    selectionAttributes: ProductSelectionAttribute[]
  ) => void;
  handleFilterChange: (selectionAttribute: ProductSelectionAttribute) => void;
}

const SearchSidebar = (props: SearchSidebarProps) => {
  const ignoredGroups = [
    "Degree of protection (IP)",
    "Line Voltage",
    "Control Voltage",
  ];

  const groupBy = (xs: any[], key: string) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const unique = (
    arr: any[],
    keyProps: string[]
  ): ProductSelectionAttribute[] => {
    const kvArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join("|");
      return [key, entry];
    });
    //@ts-ignore
    const map = new Map(kvArray);
    return Array.from(map.values()) as ProductSelectionAttribute[];
  };

  const groupedAttributes = ((attributes: ProductSelectionAttribute[]) => {
    return groupBy(unique(attributes, ["description", "value"]), "description");
  })(props.selectionAttributes);

  const sortArray = unique(props.selectionAttributes, ["description", "value"])
    .sort((i) => i.attrSeqIdx)
    .map((i) => i.description);


  return (
    <>
      {Object.keys(groupedAttributes)
        .sort((a, b) => sortArray.indexOf(a) - sortArray.indexOf(b))
        .filter((i) => !ignoredGroups.includes(i))
        .map((group, index) => {
          return (
            <LoadSideBarItems
              {...props}
              groupedAttributes={groupedAttributes}
              key={index}
              group={group}
            />
          );
        })}
    </>
  );
};

export default SearchSidebar;
