import {
    EngInfoChassis,
    EngInfoCommModule,
    EngInfoComponent,
    EngInfoController,
    EngInfoFPDModule,
    EngInfoModule,
    IODetails,
} from "../../engData/EngineeringInfo";
import {
    addModuleAtSlot,
    ChassisCompProps,
    createModule,
    getModuleSlotRestriction,
    updateChassis
} from "../../implementation/ImplGeneral";
import {
    addChassis,
    attachModule,
    chassisChanged,
    copyAccys,
    detachModule,
    getDeviceCategory,
    getEngineeringInfoFor,
    getNetPowerAvailable,
    getPowerBreakdown,
    getProjectFromChassis,
    getRack,
    isDeviceCompatibleWithChassis
} from "../../model/ChassisProject";
import {
    ActBtnInfo,
    DfltActBtnSpecs,
    LayoutActionType
} from "../../types/LayoutActions";
import { StatusLevel } from "../../types/MessageTypes";
import {
    Chassis,
    ChassisLayoutInfo,
    ChassisModule,
    DeviceType,
    EnvRating,
    IOModuleWiring,
    NO_SLOT,
    Rack,
    ChassisElement,
    ModuleSlotRestriction,
    GraphicalDevice,
    DeviceCategory,
    SelectableDevice
} from "../../types/ProjectTypes";
import { PowerBreakdownTips } from "../../types/PowerTypes";
import {
    LocAndSize,
    Size
} from "../../types/SizeAndPosTypes";
import { addRequiredAccys } from "../../util/AccessoriesHelp";
import { addAutoFix, AFType, getNewAutoFix } from "../../util/CheckerAutoFix";
import {
    DragDeviceInfo,
    DropResult,
    DropStatus,
    getBestPossibleDropStatus
} from "../../util/DragAndDropHelp";
import {
    getEngInfoForComp,
    getIOSortPrecedence,
    getModDetailsForSorting,
    getModuleEngInfo
} from "../../util/EngInfoHelp";
import {
    assignModuleSlotLocations,
    doSAPwrVltgSetsMatch,
    getFPDForChassis,
    GetFPDMapCallback,
    getVltgSetAsMask,
    getVltgSetIntersection,
    insertRequiredFPDs,
    removeAllFPDs,
    SAPwrVltgs,
    SAVltgMask
} from "../../util/FieldPowerHelp";
import {
    isEmptyLoc,
    getEmptyLoc,
    getEmptySize,
    getImgNameFromPath,
    getLocCenter,
    getScaledLoc,
    isSizeEmpty,
    offsetLoc,
    scaleSize
} from "../../util/GeneralHelpers";
import { getNewInstanceId } from "../../util/InstanceIdHelp";
import { logger } from "../../util/Logger";
import { isPlatformSnapType } from "../../util/PlatformHelp";
import {
    addLogMessage,
    getNewProjectLog,
    LogMsgLevel,
    StatusLogType
} from "../../util/ProjectLog";
import {
    areUndoSnapshotsSuspended,
    chassisChanging,
    suspendUndoSnapshots
} from "../../util/UndoRedo";
import { ImageInfo } from "../common/CommonPlatformTypes";
import { PlatformCpLX, PlatformFlex } from "../PlatformConstants";
import SnapChassisComp from "./SnapChassisComp";
import { getScaledImageSize } from "../common/Common";
import { RendInfo, RendPortion } from "../../util/SysDsgHelp";
import { StageUnitsPerMM } from "../../types/StageTypes";


const _makeEmptySnapLayout = (chasEngInfo: EngInfoChassis): ChassisLayoutInfo => {
    return {
        platform: chasEngInfo.platform,
        numFPDs: 0,
        extendedTemp: chasEngInfo.envInfo.etOk,
        conformal: chasEngInfo.envInfo.ccOk,
        slotLocs: new Array<LocAndSize>(),
        rightCapImgSrc: '',
        rightCapLoc: { x: 0, y: 0, width: 0, height: 0 },
        size: { width: 0, height: 0 }
    };
}

export type PlatformFPDMap = Map<EnvRating, EngInfoFPDModule> | undefined;

export interface SnapPlatformDetails {

    // Required
    // Scale used for images and start slot size.
    imgScaleFactor: number;

    // Unscaled size of left slot used when
    // no module occupies the left slot.
    leftSlotStartSize: Size;

    // Used as width of X-slot when we WANT
    // one to show, but we have no drag module
    // available to get size info from.
    defaultXSlotWidth: number;

    firstSlotRestricted: boolean;

    // Max number of modules right of the
    // left slot. Used when left slot is empty.
    absMaxModules: number;

    // FUTURE - cable info will be required
    // when we add 5094 FLEX I/O.
    cableSplitAllowed: boolean;

    // Used only if the platform HAS a right
    // end cap. If so, contains img src (url)
    // and unscaled size.
    rightCapInfo?: ImageInfo;

    // Optional callbacks.
    // Answers whether a given module (info) is
    // a left-slot (only) module. 
    isALeftSlotModule?: (modInfo: EngInfoModule) => boolean;

    // Answers the max number of modules that
    // are supported, NOT including:
    //   - The left slot module
    //   - Any FPD modules used
    getMaxModules?: (chassis: Chassis) => number;

    // If provided, allows a platform to
    // provide a map of EnvRating's to FPD
    // Engineeering info. Note that FPD info
    // is loaded late via this method.
    // That allows our client to use engineering
    // information not yet loaded when this
    // details object is created.
    getFPDMap?: GetFPDMapCallback;
}

const _clientMap = new Map<string, SnapPlatformDetails>();

export const RegisterSnapClientDetails = (platform: string, details: SnapPlatformDetails) => {
    if (platform) {
        if (!_clientMap.has(platform)) {
            _clientMap.set(platform, details);
        }
        else {
            logger.warn('RegisterSnapClientDetails ignoring dup: ' + platform);
        }
    }
    else {
        throw new Error('RegisterSnapClientDetails got invalid platform!');
    }
}


export const snapGetModuleSlotRestriction = (modInfo: EngInfoComponent): ModuleSlotRestriction => {
    if (modInfo.isController || modInfo.isCommModule) {
        return ModuleSlotRestriction.FirstSlotOnly;
    }
    return ModuleSlotRestriction.NotFirstSlot;
}

const _getPlatformDtls = (platform: string): SnapPlatformDetails => {
    const dtls = _clientMap.get(platform);
    if (dtls) return dtls;

    throw new Error('Unregistered platform attempted to use snap: ' + platform);
}

const _getScaledSize = (sz: Size, scale: number): Size => {
    return {
        width: sz.width * scale,
        height: sz.height * scale
    }
}

const _getLocForMod = (
    module: ChassisModule | undefined,
    snapDtls: SnapPlatformDetails,
    leftX: number
): LocAndSize => {
    let imgSize = getEmptySize();
    if (module) {
        const modEngInfo = getEngineeringInfoFor(module);
        if (modEngInfo) {
            imgSize = modEngInfo.imgSize;
        }
    }
    if (isSizeEmpty(imgSize, true)) {
        imgSize = snapDtls.leftSlotStartSize;
    }
    const locSize = _getScaledSize(imgSize, snapDtls.imgScaleFactor);
    return {
        x: leftX,
        y: 0,
        width: locSize.width,
        height: locSize.height
    }
}

const _dfltGetMaxMods = (chassis: Chassis, absMax: number): number => {
    // Default if callback not provided.
    // Get left slot module.
    const leftSlotMod = chassis.modules[0];

    // If there IS one...
    if (leftSlotMod) {

        // Get eng info for it.
        const lsEngInfo = getEngineeringInfoFor(leftSlotMod);

        // If we can...
        if (lsEngInfo) {

            // The module SHOULD be a contoller or comm module.
            // Use whichever's maxModules number.
            if (lsEngInfo.isController) {
                const asCtlrInfo = lsEngInfo as EngInfoController;
                return asCtlrInfo.maxSnapModules;
            }
            if (lsEngInfo.isCommModule) {
                const asCommInfo = lsEngInfo as EngInfoCommModule;
                return asCommInfo.maxSnapModules;
            }
            throw new Error('Unexpected first module in _getMaxChassisModules!');
        }
        else {
            throw new Error('Unexpected ERROR in CpLX _getMaxChassisModules!');
        }
    }

    // Left slot is empty. 
    return absMax;
}

export const snapUpdateChassisLayout = (chassis: Chassis) => {

    // If undo snapshots are currently suspended, so
    // are layout updates. Just return in that case.
    if (areUndoSnapshotsSuspended()) {
        return;
    }

    // Get platform details.
    const dtls = _getPlatformDtls(chassis.platform);

    // Remove any existing FPD modules from the
    // chassis, if we had any after our last layout
    // update. It's at least theoretically possible that,
    // if the chassis env rating changed, we may have had
    // FPDs in it last time, but no FPD available now.
    removeAllFPDs(chassis);

    // Get FPD mod for this chassis, if
    // there is one.
    const fpd = getFPDForChassis(chassis, dtls.getFPDMap);

    // If so...
    if (fpd) {
        // Call a helper to add any needed.
        insertRequiredFPDs(chassis, fpd);
    }
    else {
        // For the 5094, there are not any
        // FPDs, but we still need to update
        // the slot locations.
        assignModuleSlotLocations(chassis);
    }

    // See how many slots we have (after any
    // FPD adjustments were made above).
    const slots = chassis.modules.length;

    // If any...
    if (slots > 0) {

        // Update the chassis' layout info.
        chassis.layout.slotLocs = new Array<LocAndSize>();

        const leftLoc = _getLocForMod(chassis.modules[0], dtls, 0);
        chassis.layout.slotLocs.push(leftLoc);
        chassis.layout.size.height = leftLoc.height;

        let leftX = leftLoc.width;
        if (slots > 1) {
            for (let idx = 1; idx < slots; idx++) {
                const nextLoc = _getLocForMod(chassis.modules[idx], dtls, leftX);
                chassis.layout.slotLocs.push(nextLoc);
                leftX += nextLoc.width;
            }
        }

        if (dtls.rightCapInfo && dtls.rightCapInfo.imgSrc) {
            const imgWidth = dtls.rightCapInfo.width * dtls.imgScaleFactor;
            const imgHeight = dtls.rightCapInfo.height * dtls.imgScaleFactor;
            chassis.layout.rightCapImgSrc = dtls.rightCapInfo.imgSrc;
            chassis.layout.rightCapLoc = {
                x: leftX,
                y: 0,
                width: imgWidth,
                height: imgHeight
            };
            leftX += imgWidth;
        }
        else {
            chassis.layout.rightCapImgSrc = '';
        }
        
        chassis.layout.size.width = leftX;

        chassisChanged(chassis);
    }
    else {
        throw new Error('ERROR: updateSnapChassisLayout with 0 slots!');
    }
}

export const snapCreateChassis = (chasEngInfo: EngInfoChassis, psCatNo?: string): Chassis | undefined => {

    if (psCatNo) {
        logger.error('PS not yet supported in snapCreateChassis!');
    }

    // Make sure requesting platform is registered.
    const dtls = _getPlatformDtls(chasEngInfo.platform);
    if (!dtls) return undefined;

    const layout = _makeEmptySnapLayout(chasEngInfo);

    const chassis: Chassis = {
        id: getNewInstanceId(),
        bump: 0,
        dragTarget: false,
        xSlotWidth: 0,
        platform: chasEngInfo.platform,
        deviceType: DeviceType.Chassis,
        catNo: chasEngInfo.catNo,
        description: chasEngInfo.description,
        isPlaceholder: chasEngInfo.isPlaceholder,
        imgSrc: '',
        extendedTemp: chasEngInfo.envInfo.etOk,
        conformal: chasEngInfo.envInfo.ccOk,
        accysPossible: chasEngInfo.anyAccysPossible(),
        layout: layout,
        ps: undefined,
        modules: new Array<ChassisModule>(1),
        selected: false,
        parent: undefined,
        redundant: false,
        defaultIOModWiring: IOModuleWiring.Screw,
        statusLog: undefined,
    }
    updateChassis(chassis);
    return chassis;
}

// Returns the maximum size of the modules array in the chassis.
const _getMaxTotalChassisModules = (chassis: Chassis, dtls: SnapPlatformDetails): number => {

    // Use the platform-provided callback or the
    // default method to determine the max number of
    // modules supported to the RIGHT of (not including)
    // the left slot, and NOT including any FPD modules.
    const maxMods = dtls.getMaxModules
        ? dtls.getMaxModules(chassis)
        : _dfltGetMaxMods(chassis, dtls.absMaxModules);

    // The total max is 1 (for the left slot), plus
    // our maxMods number, plus the number of FPD
    // modules in the chassis.
    let maxChassisMods = 1 + maxMods + chassis.layout.numFPDs;

    // Add one more if we have an Interconnect cable.
    if (chassis.modules.some(mod => mod && mod.isInterconnect))
        maxChassisMods += 1;

    return maxChassisMods;
}

const _isLeftSlotModule = (modInfo: EngInfoModule, dtls: SnapPlatformDetails): boolean => {
    const isLeftSlotMod = dtls.isALeftSlotModule
        ? dtls.isALeftSlotModule(modInfo)
        : (modInfo.isController || modInfo.isCommModule);
    return isLeftSlotMod;
}

export const snapAttachModuleAtSlot = (chassis: Chassis, module: ChassisModule, slot: number, skipUpdate: boolean): boolean => {
    // It is STRONGLY recommended that a call to
    // snapCompactModuleArray() is made after
    // attaching modules.

    // If we do not have enough slots, add empty slots...
    if (slot >= chassis.modules.length) {
        // We will add undefined until we get to the slot
        // where the module should go.
        while (slot >= chassis.modules.length)
            chassis.modules.push(undefined);
    }
    else {
        // We have a slot present. If it's not empty...
        const mod = chassis.modules[slot];
        if (mod)
            return false;
    }

    // Set the slot and parent.
    chassis.modules[slot] = module;
    module.parent = chassis;

    // Set the slot lables.
    assignModuleSlotLocations(chassis);

    if (!skipUpdate) {
        updateChassis(chassis);
        chassisChanged(chassis);
    }

    return true;
}

// Function removes of any undefines in
// the module array and updates slot IDs.
export const snapCompactModuleArray = (chassis: Chassis) => {
    // If the platform is not a Snap Type,
    // just return.
    if (!isPlatformSnapType(chassis.platform))
        return;

    // Trim any undefined's off the tail - leave
    // slot 0 as it is even if it's undefined.
    let lenModArray = chassis.modules.length;
    for (let ritr = lenModArray - 1; ritr > 0; --ritr) {
        // If we found something, break.
        if (chassis.modules[ritr] != null)
            break;

        // Trim the tail.
        chassis.modules.length--;
    }

    // Update the mod arr length.
    lenModArray = chassis.modules.length;

    // Leave Slot 0 alone. If we have a slot, other
    // than slot 0, with nothing in it....
    if (chassis.modules.some((mod, idx) => mod == null && idx > 0)) {       
        for (let idx = 1; idx < lenModArray; ++idx) {
            const module = chassis.modules[idx];
            if (module == null) {
                // Search for a good module
                const foundModule =
                    chassis.modules.some((mod, idxMod, arrMod) => {
                        // Start looking at the slots to the right
                        // of the empty slot.
                        if (idxMod > idx) {
                            // If we have a mod.
                            if (mod) {
                                // Move the mod into the empty slot.
                                arrMod[idx] = mod;
                                // Set the slot where it was to undef.
                                arrMod[idxMod] = undefined;
                                return true;
                            }
                        }

                        return false;
                    });

                if (foundModule === false) {
                    break;
                }
            }
         }

        // Trim the tail again
        for (let ritr = lenModArray - 1; ritr > 0; --ritr) {
            // If we found something, break.
            if (chassis.modules[ritr] != null)
                break;
            chassis.modules.length--;
        }
    }

    //if slot 0 is not in the array for contoller postion 
    //due to change of empty chassis. 
    //reserving the first slot by setting it undefined.
    if(chassis.modules.length===0){
        chassis.modules.push(undefined);
    }

    assignModuleSlotLocations(chassis);
    updateChassis(chassis);
    chassisChanged(chassis);
}


// Note: For this override, when slot provided is -1, 
// module is added to right side of existing modules.
export const snapAddModuleAtSlot = (
    chassis: Chassis,
    catNo: string,
    slot: number,
    envMismatchOk: boolean): boolean => {

    // Get client's platform-specific details.
    const dtls = _getPlatformDtls(chassis.platform);

    // Get eng info for the chassis.
    const chasInfo = getEngineeringInfoFor(chassis);

    // If we can...
    if (chasInfo) {

        // Get it for the module requested.
        const modInfo = getModuleEngInfo(chassis.platform, catNo);

        // If we can...
        if (modInfo) {

            // If we have an interconnect cable,
            // ignore Env.Rating mismatches.
            if (modInfo.isInterconnect)
                envMismatchOk = true;

            // If the module is compatible with the chassis, 
            // env-wise (or we were told not to care)...
            if (envMismatchOk || modInfo.isCompatibleWithEnv(chasInfo.envInfo.rating)) {

                // Determine if the module requested is of
                // a type that's appropriate (only) for the
                // leftmost slot in the chassis (generally
                // a controller or comm module).
                const leftSlotModType = _isLeftSlotModule(modInfo, dtls);

                // If we're supposed to use slot 0...
                if (slot === 0) {

                    // Then, if that slot is empty AND the module is Ok in that slot.
                    if ((chassis.modules[0] === undefined) && leftSlotModType) {

                        // Create the module.
                        const module = createModule(chassis.platform, catNo);

                        // If we can...
                        if (module) {
                            // Add it and return true.
                            chassis.modules[0] = module;
                            module.parent = chassis;
                            module.slotIdx = slot;
                            module.slotID = slot;
                            addRequiredAccys(module);
                            updateChassis(chassis);
                            return true;
                        }
                    }
                }
                else {
                    // Whenever we ADD a new module at a specified
                    // slot, and that slot currently contains a slot
                    // filler, we'll REPLACE the slot filler with our
                    // add. Determine up front if we have that case.
                    const modAtSlot = chassis.modules[slot];
                    const onSlotFiller = (modAtSlot && modAtSlot.slotFiller) ? true : false;

                    // In the 'ReplaceChassis' functionality, we can
                    // come in here with an empty module array. If so,
                    // we need to make sure we add the Slot 0 to the
                    // module array when the slot to add is > 0.
                    if (chassis.modules.length === 0 && slot > 0)
                        chassis.modules.push(undefined);

                    // See how many total slots we have before
                    // adding the new module. Include the fact that
                    // we'll actually be removing a slot filler.
                    const slotsBeforeAdd = onSlotFiller
                        ? chassis.modules.length - 1
                        : chassis.modules.length;

                    // And get the max number of slots our
                    // client can have in this chassis.
                    const maxSlots = _getMaxTotalChassisModules(chassis, dtls);
                    
                    let allowModAddition = false;
                    // If we have an Interconnect cable...
                    if (modInfo.isInterconnect) {
                        // If we do not have an interconnect in the chassis...
                        if (!chassis.modules.some(mod => mod && mod.isInterconnect))
                            allowModAddition = true;
                    }

                    if (slotsBeforeAdd < maxSlots || allowModAddition) {

                        // Create the module.
                        const module = createModule(chassis.platform, catNo);

                        // If we can...
                        if (module) {

                            // BEFORE making any change, call our helper
                            // to handle any related undo/redo work for us.
                            chassisChanging(chassis);

                            // If we're replacing a slot filler...
                            if (onSlotFiller) {
                                // Just delete it, but suspend undo's 
                                // while doing it. We want a SINGLE undo.
                                const wasSuspended = suspendUndoSnapshots(true);
                                snapDeleteModuleAtSlot(chassis, slot);
                                suspendUndoSnapshots(wasSuspended);
                            }

                            // Set its parent prop.
                            module.parent = chassis;

                            // If the requested slot was either:
                            //   - at(or after) the existing last slot
                            //   - specified as -1...
                            if ((slot === -1) ||
                                (slot >= slotsBeforeAdd)) {
                                // Add the new mod to the end.
                                chassis.modules.push(module)

                                // Give it its new slot location.
                                module.slotIdx = slotsBeforeAdd;
                                module.slotID = slotsBeforeAdd;
                            }
                            else {
                                // Inserting somewhere. Splice in the new module.
                                chassis.modules.splice(slot, 0, module);

                                // Then starting at that slot, and moving
                                // to the right. Get each module and set its
                                // NEW slot location.
                                for (let idx = slot; idx < chassis.modules.length; idx++) {
                                    const mod = chassis.modules[slot];
                                    if (mod) {
                                        mod.slotIdx = idx;
                                        mod.slotID = idx;
                                    }
                                }
                            }

                            // Succcess.
                            addRequiredAccys(module);
                            updateChassis(chassis);
                            return true;
                        }
                    }
                    else {
                        // If we do NOT have a specific slot requested...
                        if (slot === -1) {
                            // I believe we get here when we are coming from
                            // the Add Modules Dialog where slot fillers are
                            // considered Empty Slots. Check if we have ANY 
                            // slot fillers in the chassis. If we do...
                            const idxSF = chassis.modules.findIndex(mod => mod && mod.slotFiller);
                            if (idxSF > 0) {
                                // Recurse
                                return snapAddModuleAtSlot(chassis, catNo, idxSF, envMismatchOk);
                            }
                        }
                    }
                }
            }
        }
    }

    // Failed.
    return false;
}

export const snapDeleteModuleAtSlot = (chassis: Chassis, slot: number): boolean => {
    // If slot number is in valid range...
    if ((slot >= 0) && (slot < chassis.modules.length)) {

        // Get the module at the specified slot.
        const module = chassis.modules[slot];

        // If there is one...
        if (module) {

            // BEFORE making any change, call our helper
            // to handle any related undo/redo work for us.
            chassisChanging(chassis);

            // Make sure the module doesn't have
            // a ref back to our chassis.
            module.parent = undefined;

            // If the module is in slot 0...
            if (slot === 0) {

                // Set slot content to undefined.
                chassis.modules[slot] = undefined;
            }
            else {

                // Otherwise, REMOVE the slot.
                chassis.modules.splice(slot, 1);
            }

            updateChassis(chassis);

            // Success.
            return true;
        }
        else {
            // Unexpected
            throw new Error('snapDeleteModuleAtSlot - delete module at slot with no module!');
        }
    }
    else {
        // Unexpected
        throw new Error('snapDeleteModuleAtSlot - INVALID slot number!');
    }

    // Fail. We didn't delete anything.
    return false;
}

export const snapGetChassisRenderer = (): React.FC<ChassisCompProps> => {
    return SnapChassisComp;
}

export const snapGetDefaultChassisName = (chassis: Chassis): string => {
    return chassis.platform + ' Chassis';
}

export const snapGetActionBtnInfo = (action: LayoutActionType,
    rack: Rack, slotNum: number): ActBtnInfo => {

    const slots = rack.chassis.modules.length;
    if (slotNum > slots) {
        throw new Error('Invalid slotNUm in snapGetActionBtnInfo!');
    }

    // If the slot requested is 1 to the right
    // of our chassis' last ACTUAL slot...
    if (slotNum === slots) {

        // See if the chassis can be extended
        // with another module. If so...
        if (snapCanExtendChassis(rack.chassis)) {

            // Get platform details...
            const dtls = _getPlatformDtls(rack.chassis.platform);

            // Get the location of the last actual slot.
            const lastSlotLoc = { ...rack.chassis.layout.slotLocs[slots - 1] };
            offsetLoc(lastSlotLoc, rack.ptOrg);

            // Start our 'x' (extra) slot as a copy of that.
            const xSlotLoc = { ...lastSlotLoc };

            // Place it's left side at the right
            // side of the last real slot.
            xSlotLoc.x += lastSlotLoc.width;

            // Set its width to be the platform's default.
            xSlotLoc.width = dtls.defaultXSlotWidth * dtls.imgScaleFactor;

            // Position the act btn pt inside.
            const pt = getLocCenter(xSlotLoc);
            pt.y += DfltActBtnSpecs.height;

            // And return our btn info.
            return {
                action: action,
                chassis: rack.chassis,
                slot: slotNum,
                ctrPt: pt
            };
        }
        else {
            throw new Error('Invalid extension attempt in snapGetActionBtnInfo!');
        }
    }

    const slotLoc = { ...rack.chassis.layout.slotLocs[slotNum] };
    offsetLoc(slotLoc, rack.ptOrg);
    const pt = getLocCenter(slotLoc);
    pt.y += DfltActBtnSpecs.height;
    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: pt
    };
}

export const snapGetSlotTypeRestriction =
    (chassis: Chassis, slotNum: number): ModuleSlotRestriction => {

        const dtls = _getPlatformDtls(chassis.platform);
        if (dtls.firstSlotRestricted) {
            if (slotNum === 0) {
                return ModuleSlotRestriction.FirstSlotOnly;
            }
            else {
                return ModuleSlotRestriction.NotFirstSlot;
            }
        }
        else {
            return ModuleSlotRestriction.None;
        }
}

export const snapFilterAvailableModules = (
    chassis: Chassis,
    restrict: ModuleSlotRestriction,
    unfiltered: EngInfoModule[]): EngInfoModule[] => {

    const dtls = _getPlatformDtls(chassis.platform);
    if (restrict === ModuleSlotRestriction.FirstSlotOnly) {
        return unfiltered.filter(mod => _isLeftSlotModule(mod, dtls));
    }

    return unfiltered.filter(mod => !mod.isFPD && !mod.isBankExp && !mod.isInterconnect && !mod.isBankExp && !_isLeftSlotModule(mod, dtls));
}

export const snapGetMaxNewModules = (chassis: Chassis, restrict: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number => {
    if (restrict === ModuleSlotRestriction.FirstSlotOnly) {
        return chassis.modules[0] ? 0 : 1;
    }

    const dtls = _getPlatformDtls(chassis.platform);
    const modArrLen = chassis.modules.length;

    let maxNew = _getMaxTotalChassisModules(chassis, dtls) - modArrLen;

    // If we are counting slot fillers as empty slots,
    // only check the Flex platform. 5069 CompactLogix
    // does NOT have slot fillers.
    if (treatSlotFillerAsEmptySlot && chassis.platform === PlatformFlex) {    
        for (let idx = 0; idx < modArrLen; ++idx) {
            const mod = chassis.modules[idx];
            if (mod && mod.slotFiller)
                ++maxNew;
        }
    }

    return maxNew;
}

export const snapCanExtendChassis = (chassis: Chassis) => {

    const dtls = _getPlatformDtls(chassis.platform);
    const maxSlots = _getMaxTotalChassisModules(chassis, dtls);
    return (maxSlots > chassis.modules.length);
}

// Check to see if the specified module COULD be added to
// the given chassis. If yes, answers the first slot that
// would work. If no, answers NO_SLOT (-1).
export const snapCanModuleBeAdded = (module: ChassisModule, chassis: Chassis): number => {

    // Check if the two are compatible:
    //   - same platform
    //   - environment rating
    // If not, just return NO_SLOT.
    if (!isDeviceCompatibleWithChassis(module, chassis)) {
        return NO_SLOT;
    }

    // Get registered details for the platform.
    const dtls = _getPlatformDtls(chassis.platform);

    // Get module eng info for the module.
    const modInfo = getModuleEngInfo(chassis.platform, module.catNo);

    // If we can...
    if (modInfo) {
        // If the module is designated as
        // a left - slot(only) module...
        if (_isLeftSlotModule(modInfo, dtls)) {

            // Return 0 if the left slot is
            // empty and NO_SLOT if not.
            return chassis.modules[0] ? NO_SLOT : 0;
        }
        else {
            // If the module is an Interconnect Cable and
            // the chassis already has one (only one allowed)...
            if (module.isInterconnect && chassis.modules.some(mod => mod && mod.isInterconnect))
                return NO_SLOT;

            // Determine the MAX number of
            // slots the chassis could have.
            const maxSlots = _getMaxTotalChassisModules(chassis, dtls);

            // If another could be added, answer the first slot
            // position after the current content. If not,
            // answer NO_SLOT. Note that for snap-type chassis,
            // the number of slots a given module requires
            // isn't relevant or used.
            return (maxSlots > chassis.modules.length) ? chassis.modules.length : NO_SLOT;
        }
    }
    else {
        throw new Error('Missing mod info in snapCanModuleBeAdded!');
    }
}

export const snapDuplicateChassis = (chassis: Chassis, insertCopyAt: number):
    Chassis | null => {
    // Get the associated project.
    const project = getProjectFromChassis(chassis);

    // If we can...
    if (project) {

        const psCat = chassis.ps ? chassis.ps.catNo : undefined;

        // Add a new chassis, using the catNos of the
        // old and of its power supply (if it has one).
        const dup = addChassis(project, chassis.platform, chassis.catNo,
            insertCopyAt, psCat);

        // If the add worked...
        if (dup) {

            // orig here is still the same as chassis,
            // but 'orig' makes the following easier to
            // follow.
            const orig = chassis;

            // Our dup chassis should now have whatever
            // REQUIRED accys were appropriate added to
            // the chassis itself and its dup'd power supply.
            // However, we want it to be a real duplicate,
            // so we'll copy accys from orig to dup here for
            // both of those elements.
            copyAccys(orig, dup);
            if (orig.ps && dup.ps) {
                copyAccys(orig.ps, dup.ps);
            }

            // Copy default I/O wiring type.
            dup.defaultIOModWiring = chassis.defaultIOModWiring;

            // Start optimistic regarding
            // module duplication results.
            let addFailed = false;

            // Walk all modules in the orig chassis. For each...
            for (let origSlot = 0; origSlot < orig.modules.length; origSlot++) {

                // Get the original module (if there IS one at the slot).
                const origMod = orig.modules[origSlot];

                // If so...
                if (origMod && !origMod.isFPD) {

                    // For slots 0 or 1, we'll add at the SAME slot.
                    // Otherwise, we'll always use the CURRENT length
                    // our our new chassis' modules array for a target slot,
                    // We want the module inserted at the END.
                    const slotForAdd = (origSlot <= 1) ? origSlot : dup.modules.length;

                    // Using the original's catNo, add the same module
                    // to the dup chassis at the same slot.
                    // If the add works...
                    if (snapAddModuleAtSlot(dup, origMod.catNo, slotForAdd, true)) {

                        // Get the new module from the dup chassis.
                        const newMod = dup.modules[slotForAdd];

                        // If we can...
                        if (newMod) {
                            // Then COPY whatever accys found on
                            // the original module to the new one.
                            copyAccys(origMod, newMod);
                        }
                        else {
                            addFailed = true;
                        }
                    }
                    else {
                        addFailed = true;
                    }
                }
            }

            // Deal with any problems encountered above
            // during the module dup'ing process.
            if (addFailed) {
                throw new Error('snapDuplicateChassis() could not duplicate all modules!');
            }

            return dup;
        }
    }
    return null;
}

const _insertXOffset = -5;
const _insertWidth = 90;
const _insertHeightExt = 110;

const _getInsertLoc = (chassis: Chassis, slot: number): LocAndSize => {
    const lastSlotIdx = chassis.modules.length - 1;
    const isXSlot = (slot > lastSlotIdx);
    const slotBasis = isXSlot
        ? chassis.layout.slotLocs.length - 1
        : slot;

    const loc = { ...chassis.layout.slotLocs[slotBasis] };
    if (isXSlot) {
        loc.x += loc.width;
    }
    loc.x += _insertXOffset;
    loc.width = _insertWidth;
    loc.y -= _insertHeightExt;
    loc.height += (2 * _insertHeightExt);

    const rack = getRack(chassis);
    if (rack) {
        offsetLoc(loc, rack.ptOrg);
    }
    else {
        throw new Error('getRack FAILED in _getInsertLoc');
    }

    return loc;
}

// Helper used to check for drop-on-self cases.
//    checkSlot - slot under drag pt
//    modSlot   - slot used by existing module
//    rightSide - true if drag pt is over RIGHT side of checkSlot
const _isDropOnSelfCase = (checkSlot: number, modSlot: number, rightSide: boolean): boolean => {

    // True if checkSlot and modSlot are the same.
    // we have the same 
    if (checkSlot === modSlot) {
        return true;
    }

    // If check is 1 to the left, true
    // if we're over the right side of check.
    if (checkSlot === (modSlot - 1)) {
        return rightSide;
    }

    // If check is 1 to the right, true
    // if we're over the LEFT side of check.
    if (checkSlot === (modSlot + 1)) {
        return !rightSide;
    }

    // Not on self.
    return false;
}

 const _getSnapDragDetail = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    slotNum: number
): [
        leftSlotMod: boolean,
        targetSlot: number,
        dropOnSelf: boolean,
        dropOnSlotFiller: boolean
    ] => {

    // Get client's platform-specific details.
    const dtls = _getPlatformDtls(chassis.platform);

    // See if the dragged module is a 'left-slot-only' type.
    const modInfo = getModuleEngInfo(dragInfo.dragMod.platform, dragInfo.dragMod.catNo);
    const leftSlotMod = (modInfo && _isLeftSlotModule(modInfo, dtls))
        ? true
        : false;

    // See if we have a 'local' move (not a copy).
    const localMove = (!dragInfo.copy && (dragInfo.dragMod.parent === chassis));

    // If so...
    if (localMove) {

        // As a helper if we have a drop-on-self case.
        // If so, return using the slot number passed in.
        if (_isDropOnSelfCase(slotNum, dragInfo.dragMod.slotIdx, dragInfo.rightSide)) {
            return [leftSlotMod, slotNum, true, false];
        }
    }

    // If here, we know this is NOT a local move.
    // Init our target slot to what we were given.
    let targetSlot = slotNum;
    let dropOnSlotFiller = false;

    // If our drag mod is NOT a special left-slot-only
    // module AND this isn't a local move...
    if (!leftSlotMod && !localMove) {

        // Check to see if the specified slot
        // contains a slot filler.
        const slotMod = chassis.modules[slotNum];
        if (slotMod && slotMod.slotFiller) {
            dropOnSlotFiller = true;
        }

        // If NOT, then adjust our target one to
        // the right if we're over the right side.
        if (!dropOnSlotFiller && dragInfo.rightSide) {
            targetSlot += 1;
        }
    }

    // Return using the target slot.
    return [leftSlotMod, targetSlot, false, dropOnSlotFiller];
}

export const snapGetChassisDropStatus = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slotNum: number
): DropStatus => {

    // Call a helper to tell us the best possible status
    // we could end up with, including a platform check.
    const bestDropPossible = getBestPossibleDropStatus(dragInfo, chassis);

    // Reset the 'show-insert-graphic' prop in the drag info.
    // We'll set it below if/where applicable. Note that we
    // don't need to empty existing .insertLoc. It's not
    // used unless the .show prop is true.
    dragInfo.showInsert = false;

    // If the best is NOT already NoDrop...
    if (bestDropPossible !== DropStatus.NoDrop) {

        // If we're over a slot...
        if (touchEl === ChassisElement.Slot) {

            // Call a helper to get better detail
            // about our specific situation.
            const [leftSlotMod, targetSlot, dropOnSelf, dropOnSlotFiller] =
                _getSnapDragDetail(chassis, dragInfo, slotNum);

            // We'll consider any case where a dragged,
            // non-copied module is effectively over itself
            // to be an OK drop while getting status. An
            // actual drop in that situation would be a no-op.
            // We do NOT have an 'insert' situation.
            if (dropOnSelf) {
                return bestDropPossible;
            }

            // If the drag is a left-slot-only module...
            if (leftSlotMod) {

                // Then we should expect the target slot
                // to be 0. If so, return based on whether
                // slot 0 is empty or not. In either case,
                // we do NOT have an 'insert' situation.
                if (targetSlot === 0) {
                    // Then return based on whether the
                    if (chassis.modules[0]) {
                        return DropStatus.NoDrop;
                    }
                    else {
                        return bestDropPossible;
                    }
                }
                else {
                    return DropStatus.NoDrop;
                }
            }

            // At this point, we do NOT have a left
            // slot only module. Fail on anything 
            // trying to target slot 0.
            if (targetSlot === 0) {
                return DropStatus.NoDrop;
            }

            // If the slot we're targeting is to the right of
            // the last slot the chassis already has, then
            // a drop would be to the 'X' slot. In that case
            // return our best possible, WITHOUT insert info.
            //if (targetSlot >= chassis.modules.length) {
            //    return bestDropPossible;
            //}

            // If we're still here, and NOT on a slot filler,
            // we'll show the insert graphic. 
            if (!dropOnSlotFiller) {
                dragInfo.showInsert = !dropOnSlotFiller;
                dragInfo.insertLoc = _getInsertLoc(chassis, targetSlot);
            }

            return bestDropPossible;
        }
    }

    // If we're still here, a drop isn't possible.
    return DropStatus.NoDrop;
}

const _getModChassis = (mod: ChassisModule): Chassis => {
    if (mod.parent) {
        return mod.parent;
    }
    throw new Error('Module without parent chassis in _getModChassis!');
}

export const _snapDropModule = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    targetSlot: number,
    onSlotFiller: boolean
): boolean => {

    // We should only be called when a drop has
    // been pre-qualified. As such, we expect to
    // actually succeed here.
    // Start by calling a helper to handle any
    // related undo/redo work for us.
    chassisChanging(chassis);

    // Then temporarily suspend any
    // interim undo/redo snapshots.
    suspendUndoSnapshots(true);

    let finalRslt = false;

    // See if we're swapping or not, and what our
    // dropped module's catalog number should be.
    const swap = dragInfo.swapToCatNo ? true : false;
    const modCat = dragInfo.swapToCatNo ? dragInfo.swapToCatNo : dragInfo.dragMod.catNo;

    // Get the chassis and slot idx of the 
    // drag mod PRIOR to any move.
    const dragModChassis = _getModChassis(dragInfo.dragMod);
    const origModIdx = dragInfo.dragMod.slotIdx;

    // Determine if we have a local move or copy.
    // We do, if the original and destination
    // chassis are the same.
    const local = (dragModChassis === chassis);

    // We may or may not need to do certain steps
    // below, which we can determine based on our
    // specific circumstances.
    // If we're NOT copying, we WILL need to DETACH
    // the dragged module from its old location,
    // regardless of whether we end up re-using it, or
    // replacing it with a target-suitable counterpart.
    const detachDragMod = !dragInfo.copy;

    // We'll need to ADD a new module if we're
    // copying, OR if we're moving AND need to
    // do a swap. If we DON'T add a new module,
    // then we'll be RE-ATTACHING the old one.
    const addingANewModule = dragInfo.copy
        ? true
        : swap ? true : false;

    // If we're supposed to detach...
    if (detachDragMod) {
        // Do that.
        detachModule(dragInfo.dragMod);

        // We'll want to remove the slot itself
        // if NOT the LEFT slot. If so...
        if (origModIdx > 0) {

            // Remove the old slot.
            // Note that all internal .slotIdx props
            // on modules will be reset at the end.
            dragModChassis.modules.splice(origModIdx, 1);

            // If our move is local...
            if (local) {

                // and our removed slot was to
                // the LEFT of our target slot...
                if (origModIdx < targetSlot) {

                    // Adjust the target slot accordingly.
                    targetSlot -= 1;
                }
            }
        }
    }

    // If we're adding a NEW module
    if (addingANewModule) {

        // Using the catNo we determined above, add
        // the new module to the chassis/target slot.
        // NOTE: The add will take care of modifying
        // its modules array as needed. There's no need
        // for us to have to make sure the target slot
        // is empty here. The add will ALSO automatically
        // REPLACE the existing module in the targetSlot
        // IFF that module is a slot filler.
        finalRslt = addModuleAtSlot(chassis, modCat, targetSlot);
    }
    else {
        // Not adding. We MUST be re-attaching.

        // If our target slot is NOT the left slot...
        if (targetSlot !== 0) {

            // Here, we need to manually remove an
            // existing slot filler if we're over one.
            if (onSlotFiller) {

                // Confirm that the target slot actually
                // CONTAINS a slot filler.
                const sf = chassis.modules[targetSlot];

                // If so...
                if (sf && sf.slotFiller) {

                    // Delete that before the add or attach below.
                    snapDeleteModuleAtSlot(chassis, targetSlot);
                }
                else {
                    throw new Error('Missing slot filler in _snapDropModule');
                }
            }

            // Add (or insert) a (temporarily empty) slot
            // located at the idx matching our targetSlot.
            if (targetSlot >= chassis.modules.length) {
                chassis.modules.push(undefined);
            }
            else {
                chassis.modules.splice(targetSlot, 0, undefined);
            }
        }

        // Then insert it into the specified chassis
        // at the specified slot location.
        chassis.modules[targetSlot] = dragInfo.dragMod;

        // Hook the module's .parent prop.
        dragInfo.dragMod.parent = chassis;

        finalRslt = true;
    }

    // UN-suspend undo/redo snapshots.
    suspendUndoSnapshots(false);

    // Finally, we'll update the chassis layouts
    // for whichever chassis we actually modified.
    // We'll only update the source chassis if
    // it we did a non-local move, in which case
    // we would have REMOVED a module.
    if (!local && !dragInfo.copy) {
        updateChassis(dragModChassis);
    }

    // In all cases, we'll update our target chassis.
    updateChassis(chassis);

    // Return our final answer.
    return finalRslt;
}


export const snapDropDragDeviceOnChassis = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slotNum: number
): DropResult => {

    // If we're called, a drop SHOULD be possible.
    // If all prequalifications are met...
    if ((dragInfo.dropStatus !== DropStatus.NoDrop) &&
        (chassis.platform === dragInfo.dragMod.platform) &&
        (touchEl === ChassisElement.Slot)) {

        // Call a helper to get better detail
        // about our specific situation.
        const [/*leftSlotMod*/, targetSlot, dropOnSelf, dropOnSlotFiller] =
            _getSnapDragDetail(chassis, dragInfo, slotNum);

        // If we have a drop-on-self case, we don't want to
        // actually do anything. This IS a valid case, but we'll
        // return DropFailed so no further action is taken.
        if (dropOnSelf) {
            return DropResult.DropFailed;
        }

        // Call a helper to do the rest.
        if (_snapDropModule(chassis, dragInfo, targetSlot, dropOnSlotFiller)) {
            return DropResult.DropSuccess;
        }
        else {
            return DropResult.DropFailed;
        }
    }

    throw new Error('Unexpected call to snapDropDragDeviceOnChassis?');
}

export const snapGetChassisSizeAsDrawn = (chassis: Chassis, copyMode: boolean): Size => {

    // Get platform details.
    const dtls = _getPlatformDtls(chassis.platform);

    // Start with assumption that we're NOT
    // including a 'X' (extra empty) slot.
    let xWidth = 0;

    // We will if the chassis is a drag target
    // AND it has an xSlotWidth already.
    if (chassis.dragTarget && (chassis.xSlotWidth > 0)) {
        xWidth = chassis.xSlotWidth;
    }
    else {
        // Otherwise, we will if we're either in Copy mode
        // or the chassis is selected, AND the chassis actually
        // CAN be extended.
        const extendPossible = chassis.selected || copyMode;
        if (extendPossible && snapCanExtendChassis(chassis)) {
            xWidth = dtls.defaultXSlotWidth * dtls.imgScaleFactor;
        }
    }

    // Id we DO have an 'extra slot' width...
    if (xWidth > 0) {

        // Start with the chassis' full size.
        const sz = { ...chassis.layout.size };

        // Then adjust width.
        // If a the platform has a right end cap,
        // remove its with.
        if (dtls.rightCapInfo) {
            sz.width -= dtls.rightCapInfo.width;
        }

        // Then add the specified extra width
        sz.width += xWidth;

        return sz;
    }

    // Otherwise, return the actual size.
    return { ...chassis.layout.size };
}

export const snapGetXSlotWidthFor = (modInfo: EngInfoComponent): number => {
    const restrict = getModuleSlotRestriction(modInfo);
    const xWidth = (restrict === ModuleSlotRestriction.FirstSlotOnly)
        ? 0
        : getScaledImageSize(modInfo).width;
    return xWidth;
}

export const snapGetDefaultXSlotWidth = (platform: string): number => {
    const dtls = _getPlatformDtls(platform);
    return dtls.defaultXSlotWidth * dtls.imgScaleFactor;
}

const _collectUniqueVtlgs = (vltgs: SAPwrVltgs, collected: SAPwrVltgs[]) => {

    // Walk the collected array of sets. For each...
    for (let idx = 0; idx < collected.length; idx++) {

        // If the incoming vltgs set effectively
        // matches this one, just return.
        if (doSAPwrVltgSetsMatch(vltgs, collected[idx])) {
            return;
        }
    }

    // We didn't find an existing match.
    // Add the new one to our collection.
    collected.push(vltgs);
}

const _consolidateVltgs = (collected: SAPwrVltgs[]) => {

    // If we get MORE THAN 1 entry in our
    // collection of sets...
    if (collected.length > 1) {

        // Walk from back to front. For each...
        for (let i = collected.length - 1; i > 0; i--) {

            // Get the entry.
            const si = collected[i];

            // Then walk all OTHER sets,
            // again moving back to front.
            for (let j = i - 1; j >= 0; j--) {

                // Get the other entry.
                const sj = collected[j];

                if (si && sj) {
                    // Get the intersection of the 2 sets.
                    const intSect = getVltgSetIntersection(si, sj);

                    // If there IS any...
                    if (intSect.size > 0) {

                        // Replace the other (inner) set
                        // with the intersection.
                        collected[j] = intSect;

                        // And remove the outer set.
                        collected.splice(i, 1);
                    }
                }
            }
        }
    }
}

export const snapCheckFieldPower = (chassis: Chassis) => {
    // If the platform does not have FPDs...
    if (chassis.platform !== PlatformCpLX)
        return;

    const dtls = _getPlatformDtls(chassis.platform);

    // Get the FPD mod that would be used
    // for this chassis, if there is one.
    // If not, just return.
    const fpd = getFPDForChassis(chassis, dtls.getFPDMap);
    if (!fpd) return;

    // We'll start by counting FPDs.
    let numFPDs = 0;

    // Walk all the modules, and add any we find.
    for (let slot = 0; slot < chassis.modules.length; slot++) {
        const mod = chassis.modules[slot];
        if (mod && mod.isFPD) {
            numFPDs++;
        }
    }

    // The only check we do (for now) is to see if
    // we could get by with FEWER FPDs than are currently
    // present. We'd never be able to improve things
    // unless we currently have AT LEAST 2.
    if (numFPDs < 2) {
        return;
    }

    // Set up a set to hold unique catalog
    // numbers in the chassis. Start by
    // adding the FPD's catNo to the set.
    const catNos = new Set<string>();
    catNos.add(fpd.catNo);

    const uniqueVltgSets = new Array<SAPwrVltgs>();

    // Walk all slots again. For each...
    for (let slot = 0; slot < chassis.modules.length; slot++) {

        // Get the module in the slot.
        const mod = chassis.modules[slot];

        // If we can, and it's not in our set yet...
        if (mod && !catNos.has(mod.catNo)) {

            // Add it to the set.
            catNos.add(mod.catNo);

            // Get eng info.
            const modInfo = getEngineeringInfoFor(mod);

            // If we can...
            if (modInfo) {

                // If this is a pure consumer of SA power...
                if (modInfo.saPwrConsumer && !modInfo.saPwrSupplier) {

                    // Then it SHOULD have useable voltages.
                    const vltgs = modInfo.getSAPwrVltgsUsed();

                    // If so...
                    if (vltgs) {

                        // Add to out array of unique sets
                        // if different than anything we've seen.
                        _collectUniqueVtlgs(vltgs, uniqueVltgSets);
                    }
                    else {
                        throw new Error('Unexpected ERROR in snapCheckFieldPower!');
                    }
                }
            }
            else {
                logger.error('Missing eng info in snapCheckFieldPower for: ' + mod.catNo);
            }
        }
    }

    //logger.warn('Num sets before consolidate: ' + uniqueVltgSets.length);

    // See if we can do better.
    let canOptimize = false;

    // Even if we can't do any consolidation of
    // our sets, we should be able to get away
    // with 1 FPD between each (numSets - 1).
    // If we already have MORE than that...
    if (numFPDs >= uniqueVltgSets.length) {

        // Optimization IS possible.
        // There's no point in wasting effort
        // attempting to consolidate any further
        canOptimize = true;
    }
    else {
        // Try to consolidate our vltg sets.
        // This merges set entries together
        // where intersections occur, leaving
        // remaining entries those intersections.
        _consolidateVltgs(uniqueVltgSets);
        //logger.warn('Num sets AFTER consolidate: ' + uniqueVltgSets.length);

        // If we NOW have more than we need.
        if (numFPDs >= uniqueVltgSets.length) {

            // Then optimization is possible.
            canOptimize = true;
        }
    }

    if (canOptimize) {
        if (chassis.statusLog == null)
            chassis.statusLog = getNewProjectLog(StatusLogType.chassis);
        const af = getNewAutoFix(chassis.platform,
            chassis.id, AFType.Snap_InefficientFPDs);
        addAutoFix(af);
        const msg = 'The number of FPD modules in the chassis ' +
            'can be reduced by optimizing the placement of the ' +
            'I/O Modules. If you have specifically positioned the ' +
            'FPDs considering your SA Power draw requirements, it ' +
            'is suggested that you leave the placement as is. ';
        addLogMessage(chassis.statusLog, msg, LogMsgLevel.info,
            chassis, af.id, 'Optimize');
    }

}

const _optFPDCompareByIOType = (dtls1: IODetails, dtls2: IODetails): number => {
    const sortPrec1 = getIOSortPrecedence(dtls1);
    const sortPrec2 = getIOSortPrecedence(dtls2);
    return (sortPrec1 > sortPrec2) ? -1 : 1;
}

const _optFPDCompareByType = (info1: EngInfoComponent, info2: EngInfoComponent): number => {

    // Use a helper to give us details we
    // care about for each module.
    const [isIO1, dtls1, saPwr1] = getModDetailsForSorting(info1);
    const [isIO2, dtls2, saPwr2] = getModDetailsForSorting(info2);

    // If the SA power consumption is NOT the
    // same, we'll return based on that. We
    // want the higher draw to come first in order.
    if (saPwr1 !== saPwr2) {
        return (saPwr1 > saPwr2) ? -1 : 1;
    }

    // If we're still here, we'll compare be I/O
    // type information. If we have all of that...
    if (isIO1 && dtls1 && isIO2 && dtls2) {
        return _optFPDCompareByIOType(dtls1, dtls2);
    }
    else {
        // Won't compare. 
        return 0;
    }
}

// Main sorting predicate.
const _optFPDModCompare = (m1: ChassisModule | undefined, m2: ChassisModule | undefined): number => {

    // Sanity check that we got 2
    // actual modules. If so...
    if (m1 && m2) {

        // If catNos match, mods are
        // the same for sort ordering.
        if (m1.catNo === m2.catNo) {
            return 0;
        }
        else {
            // Different mods. Get eng info for both.
            const saInfo1 = _optFPDMapCatToSAInfo.get(m1.catNo);
            const saInfo2 = _optFPDMapCatToSAInfo.get(m2.catNo);

            // If we can...
            if (saInfo1 && saInfo2) {

                // Compare using eng info.
                return _optFPDCompareByType(saInfo1.engInfo, saInfo2.engInfo);
            }
            else {
                throw new Error('ERROR - missing eng info in _optFPDModCompare');
            }
        }
    }
    else {
        throw new Error('ERROR - undefined mod in _optFPDModCompare');
    }
}

const _optFPDIsModuleAcDcSAPwr = (saInfo: OptFPDSAInfo): boolean => {
    const vltgMask = getVltgSetAsMask(saInfo.engInfo.getSAPwrVltgsUsed());
    return ((vltgMask & SAVltgMask.DC) !== 0 && (vltgMask & SAVltgMask.AC) !== 0);
}

const _optFPDAddAcDcModules = (arrTarget: ChassisModule[], arrAcDcMods: ChassisModule[], targetArrDraw: number, pwrProvided: number) => {
    // If we do not have anymore AC/DC modules...
    if (!arrAcDcMods)
        return;

    // arrAcDcMods is sorted by lowest to
    // highest SA Pwr consumed.
    let pwrDraw = targetArrDraw;
    let idxTarget = arrAcDcMods.length - 1;
    while (idxTarget >= 0) {
        const acdcMod = arrAcDcMods[idxTarget];
        const saInfo = _optFPDMapCatToSAInfo.get(acdcMod?.catNo);
        if (saInfo) {
            if (pwrDraw + saInfo.saPwrDraw <= pwrProvided) {
                arrTarget.push(acdcMod);
                pwrDraw += saInfo.saPwrDraw;
                arrAcDcMods.splice(idxTarget, 1);
            }
        }
        idxTarget--;
    }
}

const _optFPDGetProvidedSAPower = (
    slot0Mod: ChassisModule | undefined,
    fpd: EngInfoFPDModule):
    [slot0Pwr: number, fpdPwr: number, DcSAPwrFirst: boolean] => {

    const saPwrFpdProvided = getPowerBreakdown(fpd.platform, fpd.catNo).saPower;
    if (!slot0Mod)
        return [saPwrFpdProvided, saPwrFpdProvided, false];

    // By default, AC modules are placed before DC
    // modules. Check the Slot 0 module for DC Only.
    // Start the Slot 0 SA pwr provided equal to the
    // FPD power provided.
    let saPwrSlot0Provided = saPwrFpdProvided;
    let dcSAPwrFirst = false;

    const slot0Info = getModuleEngInfo(slot0Mod.platform, slot0Mod.catNo);
    if (slot0Info && slot0Info.saPwrSupplier && slot0Info.saPwrVltgsAvail) {
        // Determine if slot 0 is DC SA Pwr ONLY.
        const mask = getVltgSetAsMask(slot0Info.saPwrVltgsAvail)
        dcSAPwrFirst = (mask === SAVltgMask.VDC24);

        // We either have a comm or controller in Slot 0.
        let infoCtrlComm: EngInfoController | EngInfoCommModule = slot0Info as EngInfoController;
        // Get the net SA Pwr provided
        if (!slot0Info.isController) 
            infoCtrlComm = slot0Info as EngInfoCommModule;
        // Get the power breakdown and set the SA Pwr.
        const pb = getNetPowerAvailable(infoCtrlComm.powerAvail, infoCtrlComm.powerUsed);
        saPwrSlot0Provided = pb.saPower
    }

    return [saPwrSlot0Provided, saPwrFpdProvided, dcSAPwrFirst];
}


interface OptFPDSAInfo {
    engInfo: EngInfoModule;
    saPwrDraw: number;
}
const _optFPDMapCatToSAInfo = new Map<string, OptFPDSAInfo>();

export const snapOptimizeFPDs = (chassis: Chassis): boolean => {

    // We'll control snapshots ourselves, since
    // we may be adding more than 1 new chassis.
    // Take a snapshot for undo/redo.
    chassisChanging(chassis);

    // Suspend any more snapshots while we add our chassis.
    const snapshotsWereSuspended = suspendUndoSnapshots(true);

    const dtls = _getPlatformDtls(chassis.platform);

    // Remove any existing FPD modules from the
    // chassis. If any ARE removed (they should be
    // if we're in this function), make sure
    // all remaining modules get tagged (at least
    // temporarily) with their new slotIdx locations.
    if (removeAllFPDs(chassis)) {
        assignModuleSlotLocations(chassis);
    }

    // Get the FPD mod that would be used
    // for this chassis, if there is one.
    // If not, just return.
    const fpd = getFPDForChassis(chassis, dtls.getFPDMap);
    if (!fpd) return false;

    // See how many mods we have in the chassis, 
    // NOT including whatever's in the left slot.
    const numNonLeftSlotMods = chassis.modules.length - 1;

    // If there are enough to be able to do any optization...
    if (numNonLeftSlotMods >= 2) {

        // COLLECT INFORMATION

        // Defined some information vars.
        const modsAcDC: ChassisModule[] = [];
        const modsAc: ChassisModule[] = [];
        const modsDc: ChassisModule[] = [];
        let dcDraw = 0;
        let acDraw = 0;

        // Get a copy of all modules except for
        // the Slot 0 module.
        const modsSrc = chassis.modules.slice(1);

        // Collect all module info
        modsSrc.forEach(mod => {
            // If we have a module...
            if (mod) {
                let saInfo = _optFPDMapCatToSAInfo.get(mod.catNo)
                // Add it to the map if not there...
                if (!saInfo) {
                    const info = getModuleEngInfo(chassis.platform, mod.catNo);
                    if (info) {
                        saInfo = {
                            engInfo: info,
                            saPwrDraw: info.powerUsed.saPower,
                        };

                        _optFPDMapCatToSAInfo.set(mod.catNo, saInfo);
                    }
                }

                // If we could NOT get the info OR the mod
                // is AC/DC SA Pwr, add it to the AC/DC array.
                // Otherwise, add it to the AC or the DC array.
                if (!saInfo || _optFPDIsModuleAcDcSAPwr(saInfo)) {
                    modsAcDC.push(mod);
                }
                else {
                    // Determine if the Mod is AC or DC. Add
                    // it to the appropriate array and tally 
                    // pwr consumed.
                    const vltgMask = getVltgSetAsMask(saInfo.engInfo.getSAPwrVltgsUsed());
                    if (vltgMask === SAVltgMask.DC) {
                        dcDraw += saInfo.saPwrDraw;
                        modsDc.push(mod);
                    }
                    else {
                        acDraw += saInfo.saPwrDraw;
                        modsAc.push(mod);
                    }
                }
            }
        });


        // SORT ARRAYS AND DISTRIBUTE AC/DC MODULES
        
        // By default, AC modules are placed before DC
        // modules. Get the provided SA power from slot 0
        // and the FPD. Also, check the Slot 0 module for
        // DC SA Pwr Only. Note: If Slot 0 is empty, we
        // assume the power provided to be equal to the
        // SA Pwr provided by the FPD.
        const [saPwrSlot0Provided, saPwrFpdProvided, saDCModsFirst] = _optFPDGetProvidedSAPower(chassis.modules[0], fpd);

        // Determine the AC and DC provided power.
        const dcPwrProvided = (saDCModsFirst ? saPwrSlot0Provided : saPwrFpdProvided);
        const acPwrProvided = (!saDCModsFirst ? saPwrSlot0Provided : saPwrFpdProvided);

        // Sort the AC/DC module array by SA pwr
        // consumed and reverse the order so that
        // highest consumers are at the end.
        modsAcDC.sort(_optFPDModCompare);
        modsAcDC.reverse();

        // Sort the AC and DC arrays.
        modsAc.sort(_optFPDModCompare);
        modsDc.sort(_optFPDModCompare);

        // We need to determine the distribution of the
        // AC/DC modules. If we do not have a slot 0 module,
        // we will add as many AC/DC mods to the array being
        // powered by the FPD without exceeding its capacity
        // and the rest will be added to the slot 0 powered
        // array. Otherwise, we add as many as possible to
        // the slot 0 powered array without exceeding it,
        // then add the rest to the FPD powered array.
        const distrToDCFirst = (acDraw > dcDraw || !chassis.modules[0]);

        if (distrToDCFirst) {
            _optFPDAddAcDcModules(modsDc, modsAcDC, dcDraw, dcPwrProvided);
            // Add any AC/DC modules left to the AC array. 
            modsAcDC.forEach(acdcMod => modsAc.push(acdcMod));
        }
        else {
            _optFPDAddAcDcModules(modsAc, modsAcDC, acDraw, acPwrProvided);
            // Add any AC/DC modules left to the DC array. 
            modsAcDC.forEach(acdcMod => modsDc.push(acdcMod));
        }


        // DETACH EXISTING MODS AND SANITY CHECK PROGRESS

        // Detach all of the modules except slot 0.
        modsSrc.forEach(mod => {
            if (mod) {
                detachModule(mod)
            }
            else {
                throw new Error('ERROR: undefined mod after sort in snapOptimizeFPDs!');
            }
        });

        // Sanity checks.
        // The chassis size should still be the SAME
        // as what it was after we removed any FPDs.
        if (chassis.modules.length != (numNonLeftSlotMods + 1)) {
            throw new Error('ERROR: chassis size changed after detaching mods.');
        }

        // ALL slots starting at slot 1 should
        // be temporarily EMPTY at this point.
        for (let idx = 1; idx < chassis.modules.length; idx++) {
            if (chassis.modules[idx]) {
                throw new Error('Unexpected ERROR in snapOptimizeFPDs after detach.');
            }
        }


        // ADD SORTED/OPTIMIZED MODULES BACK TO CHASSIS

        // Add the AC and DC arrays back to the
        // module array. By default, AC before DC.
        let firstArray = modsAc;
        let secondArray = modsDc;
        if (saDCModsFirst) {
            firstArray = modsDc;
            secondArray = modsAc;
        }

        // Re-attach all modules in our sorted array back
        // to the chassis, each in their new slot locaton.
        let insertIdx = 0;
        let arrLen = firstArray.length;
        for (let idx = 0; idx < arrLen; ++idx) {
            const mod = firstArray[idx];
            if (mod) {
                if (!attachModule(chassis, mod, 1 + insertIdx++)) {
                    throw new Error('ERROR re-attaching module in snapOptimizeFPDs');
                }
            }
            else {
                throw new Error('ERROR missing mod during re-attach in snapOptimizeFPDs');
            }
        }

        arrLen = secondArray.length;
        for (let idx = 0; idx < arrLen; ++idx) {
            const mod = secondArray[idx];
            if (mod) {
                if (!attachModule(chassis, mod, 1 + insertIdx++)) {
                    throw new Error('ERROR re-attaching module in snapOptimizeFPDs');
                }
            }
            else {
                throw new Error('ERROR missing mod during re-attach in snapOptimizeFPDs');
            }
        }
    }

    // We're done. Set undo suspends
    // back to where we started.
    suspendUndoSnapshots(snapshotsWereSuspended);

    // Finally, update our chassis layout.
    // This step will add FPDs back in at
    // appropropriate (new) locations.
    updateChassis(chassis);
    return true;
}


const _chassis5069SAPwrMsg =
    'SA Power is supplied at the Controller, Adapter, ' +
    'or Field Power Distributor(s) within the chassis. ' +
    'Review those modules for details.';
const _chassis5094SAPwrMsg =
    'SA Power is connected directly to I/O Modules. ' +
    'See Module Details for specific information.';

export const snapGetDevicePowerTips = (device: GraphicalDevice, singleLineFrm?: boolean): PowerBreakdownTips => {
    const tips: PowerBreakdownTips = {};

    const platform = device.platform;
    const label = (singleLineFrm ? 'N/A' : 'Not Applicable');
    const category = (device.deviceType === DeviceType.FPD ? DeviceCategory.Module : getDeviceCategory(device.deviceType));
    switch (category) {
        case DeviceCategory.PS:
        case DeviceCategory.Chassis:
            {
                const chassis = (category === DeviceCategory.PS ? (device as SelectableDevice).parent : device as Chassis);
                // The chassis should always be a Placeholder.
                if (!chassis || !chassis.isPlaceholder)
                    throw new Error('snapGetChassisPowerBreakdownTips(): Invalid Chassis!')

                // We have a 5069 or 5094 chassis.
                // Note: 5094 Flex has a fake 'placeholder'
                // chassis, but has ONE SA/MOD power supplier,
                // where CompactLogix could have more than one
                // SA power supplier (Ctrl/Comm and any FPDs).
                const chassisSAMsg = (platform === PlatformCpLX ? _chassis5069SAPwrMsg : _chassis5094SAPwrMsg);

                // Start by asking if we have a Slot 0 Module,
                // which is the starting SA/MOD power provider.
                if (chassis.modules.length === 0 || chassis.modules[0] == null) {
                    tips.tipSA = {
                        replTipText: chassisSAMsg,
                        replTipStatus: StatusLevel.Info,
                        repLabel: label,
                    };

                    // Single Line Format indicates this will be in
                    // the MODAL Issues Dialog. If so, set the status 
                    // level to 'NA' - this will prevent the icon from 
                    // showing in the modal version (as well as the tip).
                    const statusMOD = (singleLineFrm ? StatusLevel.NA : StatusLevel.Info);
                    tips.tipMod = {
                        replTipText: 'MOD Power is supplied from the Slot 0 module, which does not exist.',
                        replTipStatus: statusMOD,
                        repLabel: label,
                    };
                }
                else {
                    tips.tipSA = {
                        replTipText: chassisSAMsg,
                        replTipStatus: StatusLevel.Info,
                        repLabel: label,
                    };
                }
            }
            break;

        case DeviceCategory.Module:
            {
                const module = device as ChassisModule;
                if (module.isFPD) {
                    tips.tipMod = {
                        replTipText: 'An FPD module does not supply MOD Power.',
                        replTipStatus: StatusLevel.Info,
                        repLabel: label,
                    }
                }
                else if (platform === PlatformFlex) {
                    // If we have a MOD Power supplier, but not an SA Power supplier...
                    const modInfo = getEngInfoForComp(platform, module.catNo);
                    if (modInfo && modInfo.modPwrSupplier && !modInfo.saPwrSupplier) {
                        tips.tipSA = {
                            replTipText: _chassis5094SAPwrMsg,
                            replTipStatus: StatusLevel.Info,
                            repLabel: label,
                        }
                    }
                }
            }
            break;
    }

    return tips;
}

const _tallyRendInfoLocs = (tally: LocAndSize, loc: LocAndSize) => {
    if (isEmptyLoc(tally)) {
        tally.height = loc.height;
        tally.width = loc.width;
        // Ignore the X/Y. They're reset to 0.
        //tally.x = loc.x;
        //tally.y = loc.y;
    }
    else {
        tally.width += loc.width;
        tally.height = Math.max(tally.height, loc.height);
        // Ignore the X/Y. They're reset to 0.
        //tally.x = Math.min(tally.x, loc.x);
        //tally.y = Math.min(tally.y, loc.y);
    }
}

export const snapGetChassisRendInfo = (chassis: Chassis): RendInfo[] => {
    const layout = chassis.layout;

    const rendInfo = new Array<RendInfo>();

    const scaleAdj = 1.0 / StageUnitsPerMM;

    let locSizeTrack: LocAndSize = getEmptyLoc();
    let bank2OffsetX = 0;

    // Create an array of objects to hold
    // info about EACH image component.
    let imgEls = new Array<RendPortion>();

    const numSlots = chassis.modules.length;
    if (layout.slotLocs.length === numSlots) {
        for (let slotIdx = 0; slotIdx < numSlots; slotIdx++) {
            const module = chassis.modules[slotIdx];
            const slotLoc = layout.slotLocs[slotIdx];

            // Offset the location when we have
            // a second bank.
            slotLoc.x -= bank2OffsetX;
     
            if (module) {
                if (module.isInterconnect) {
                    // Finalize the first bank
                    imgEls.forEach(el => {
                        el.loc = getScaledLoc(el.loc, scaleAdj);
                    })

                    let bank1Size: Size = { width: locSizeTrack.width, height: locSizeTrack.height };
                    bank1Size = scaleSize(bank1Size, scaleAdj, true);

                    rendInfo.push({
                        size: bank1Size,
                        els: imgEls
                    });

                    // Clear our elements array
                    imgEls = new Array<RendPortion>();
                    // Clear our size tracker.
                    locSizeTrack = getEmptyLoc();

                    // Store the X Offset. We'll need to
                    // adjust each element in the second
                    // bank so the bank starts at x = 0.
                    bank2OffsetX = slotLoc.x + slotLoc.width;

                    // Move to the next bank...
                    continue;
                }

                _tallyRendInfoLocs(locSizeTrack, slotLoc);

                imgEls.push({
                    loc: slotLoc,
                    image: getImgNameFromPath(module.imgSrc)
                });
            }
            else {
                // Should not happen in snap!
                _tallyRendInfoLocs(locSizeTrack, slotLoc);
                imgEls.push({
                    loc: slotLoc,
                    image: ''
                });
            }
        }

        // Add the end cap. Offset it if needed.
        const locEndCap = { ...layout.rightCapLoc };
        locEndCap.x -= bank2OffsetX;
        imgEls.push({
            loc: locEndCap,
            image: getImgNameFromPath(layout.rightCapImgSrc)
        });

        // Set the final width.
        locSizeTrack.width += layout.rightCapLoc.width;
    }

    // Scale the elements.
    imgEls.forEach(el => {
        el.loc = getScaledLoc(el.loc, scaleAdj);
    })

    // Scale the final size.
    let finalSize: Size = { width: locSizeTrack.width, height: locSizeTrack.height };
    finalSize = scaleSize(finalSize, scaleAdj, true);

    rendInfo.push({
        size: finalSize,
        els: imgEls
    });

    return rendInfo;
}