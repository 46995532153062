import { getChassisDimensions } from "../implementation/ImplGeneral";
import { getInfoAllBanks, getProjectFromChassis, getRedundantChassisNameAndID } from "../model/ChassisProject";
import { isEngDataLoaded } from "../model/EngineeringData";
import { getFamilyFromPlatform } from "../platforms/PlatformIDs";
import { BOMItemType } from "../summary/SummaryHelp";
import { Chassis } from "../types/ProjectTypes";
import { BOMItemExch, ChassisPropertiesExch, ChassisPropHeaderExch } from "../userProject/UserProjectTypes";
import { Dimensions } from './DimensionHelp'


export const addChassisPropMainHeader = (chassisProp: ChassisPropertiesExch, chassis: Chassis, redundant: boolean) => {
    const mainHeader: ChassisPropHeaderExch = {
        headerName: 'General Chassis Information',
        items: [],
        headers: [],
    }

    const project = getProjectFromChassis(chassis);

    // Add the items...
    if (project && project.config.projectName) {
        mainHeader.items.push(
            {
                name: 'Configuration Name',
                value: project.config.projectName
            }
        );
    }

    let name = chassis.name;
    if (name) {
        if (redundant)
            [name,] = getRedundantChassisNameAndID(chassis);

        mainHeader.items.push(
            {
                name: 'Chassis Name',
                value: name
            }
        );
    }

    mainHeader.items.push(
        {
            name: 'Family',
            value: getFamilyFromPlatform(chassis.platform)
        }
    );

    mainHeader.items.push(
        {
            name: 'Platform',
            value: chassis.platform
        }
    );

    // Add the Main Header to the props
    chassisProp.headers.push(mainHeader);
}

export const chassisPropsAddDimensionItems = (header: ChassisPropHeaderExch, dims: Dimensions) => {
    header.items.push(
        {
            name: 'Height',
            value: `${dims.height} mm`
        }
    );

    header.items.push(
        {
            name: 'Width',
            value: `${dims.width} mm`
        }
    );

    header.items.push(
        {
            name: 'Depth',
            value: `${dims.depth} mm`
        }
    );

}

export const addChassisPropSizeHeader = (chassisProp: ChassisPropertiesExch, chassis: Chassis, __bankNumber: number = 0) => {
    const sizeHeader: ChassisPropHeaderExch = {
        headerName: 'Chassis Size',
        items: [],
        headers: [],
    }

    const haveEngData = isEngDataLoaded(chassis.platform);
    const arrBankInfo = getInfoAllBanks(chassis);
    if (arrBankInfo.length === 1) {
        // Add the items...
        sizeHeader.items.push(
            {
                name: '# of Slots/Modules',
                value: `${chassis.modules.length}`
            }
        );

        if (haveEngData) {
            const [chassisDims, ] = getChassisDimensions(chassis);
            chassisPropsAddDimensionItems(sizeHeader, chassisDims);
        }

        // Add the Size Header to the props
        chassisProp.headers.push(sizeHeader);

        // We're done.
        return;
    }

    let arrBankDims: Dimensions[] = [];
    if (haveEngData)
        [, arrBankDims] = getChassisDimensions(chassis);

    // We have more than 1 bank. For each one...
    arrBankInfo.forEach((info, idx) => {
        // Add a Bank header
        const bankHeader: ChassisPropHeaderExch = {
            headerName: `Bank #${idx + 1}`,
            items: [],
            headers: [],
        };

        bankHeader.items.push(
            {
                name: '# of Slots/Modules',
                value: `${info.slotsInBank}`
            }
        );

        if (idx < arrBankDims.length) {
            const bankDims = arrBankDims[idx];
            chassisPropsAddDimensionItems(bankHeader, bankDims);
        }

        // Add the Bank Header to the Size Header.
        sizeHeader.headers.push(bankHeader);
    });

    // Add the Size Header to the Main Header
    chassisProp.headers.push(sizeHeader);
}


export const addChassisPropPartsHeader = (chassisProp: ChassisPropertiesExch, chassis: Chassis, bomItemEx?: BOMItemExch) => {
    const partsHeader: ChassisPropHeaderExch = {
        headerName: 'Part List',
        items: [],
        headers: [],
    }

    // Note: We could start iterating the children here, but we 
    // are NOT adding any more main headers under the 'partsHeader'.
    // Pass the bomItemEx to addChassisPropBOMItem if we have it.
    if (bomItemEx)
        addChassisPropBOMItem(bomItemEx, partsHeader);

    chassisProp.headers.push(partsHeader);
}

const addChassisPropBOMItem = (item: BOMItemExch, parentHeader: ChassisPropHeaderExch) => {
    // If we have a product...
    if (item.templateTitle === BOMItemType.Product) {
        const itemHeader: ChassisPropHeaderExch = {
            headerName: item.name,
            catalogNumber: item.name,
            items: [],
            headers: [],
        };


        if (item.description.length) {
            itemHeader.items.push(
                {
                    name: 'Description',
                    value: `${item.description}`
                }
            );
        }

        itemHeader.items.push(
            {
                name: 'Quantity',
                value: `${item.quantity}`
            }
        );

        parentHeader.headers.push(itemHeader);
    }

    // If the item (Product/Header/?) has children...
    if (item.children) {
        // Recurse - we are only adding products.
        item.children.forEach(child => {
            addChassisPropBOMItem(child, parentHeader);
        });
    }
}
