import {
    GeneralImplSpec,
    getChassisDimensions,
    RegisterGeneralImpl,
} from "../../../implementation/ImplGeneral";
import { getDeviceDimensions, getTotalChassisDimensionsFromBanks } from "../../../model/ChassisProject";
import { isEngDataLoaded } from "../../../model/EngineeringData";
import {
    Chassis,
} from "../../../types/ProjectTypes";
import { Size } from "../../../types/SizeAndPosTypes";
import { StageUnitsPerMM } from "../../../types/StageTypes";
import { BOMItemExch, ChassisPropertiesExch, ChassisPropHeaderExch } from "../../../userProject/UserProjectTypes";
import { addChassisPropMainHeader, addChassisPropPartsHeader, addChassisPropSizeHeader, chassisPropsAddDimensionItems } from "../../../util/ChassisPropertiesHelp";
import { addDimensionsForChassis, Dimensions, getEmptyDimensions } from "../../../util/DimensionHelp";
import { snapAddModulesToChassis } from "../../common/HardwareGen";
import { PlatformFlex } from "../../PlatformConstants";
import { snapConfigureChassis } from "../../snap/SnapChassisConfig";
import {
    RegisterSnapClientDetails,
    snapAddModuleAtSlot,
    snapCanExtendChassis,
    snapCanModuleBeAdded,
    snapCreateChassis,
    snapDeleteModuleAtSlot,
    snapDropDragDeviceOnChassis,
    snapDuplicateChassis,
    snapFilterAvailableModules,
    snapGetActionBtnInfo,
    snapGetChassisDropStatus,
    snapGetChassisRenderer,
    snapGetChassisRendInfo,
    snapGetChassisSizeAsDrawn,
    snapGetDefaultChassisName,
    snapGetDefaultXSlotWidth,
    snapGetDevicePowerTips,
    snapGetMaxNewModules,
    snapGetModuleSlotRestriction,
    snapGetSlotTypeRestriction,
    snapGetXSlotWidthFor,
    SnapPlatformDetails,
    snapUpdateChassisLayout
} from "../../snap/SnapGeneralImpl";
import { imageRepository } from "../../../imageHelp/ImageRepos";

// Image widths look pretty consistent @ 136px.
const _flexElemImageHt = 129; // (129/121)pixels
const _flexNominalHt = 93;   // (92.7/88.7) millimeters
const _flexImageSizeToMM = _flexNominalHt / _flexElemImageHt;
const _flexImageScaleFactor = _flexImageSizeToMM * StageUnitsPerMM;

const _rightCapImgSize: Size = { width: 8, height: 129 }; // height (129 for end cap and empty slot)

export const flexGetEmptySlotImage = (chassis: Chassis): string => {
    chassis;
    return imageRepository.getImageSource('5094_Empty.png')
}

const flexGetChassisDimensions = (chassis: Chassis): [total: Dimensions, banks: Dimensions[]] => {
    let dims = getEmptyDimensions();
    const banks: Dimensions[] = [];
    chassis.modules.forEach(mod => {
        if (mod) {
            if (mod.isInterconnect) {
                // Add the interconnect dims and push
                // the first bank dims.
                const dimsIC = getDeviceDimensions(mod);
                dims = addDimensionsForChassis(dims, dimsIC);
                banks.push(dims);

                // Start a new bank as the Interconnect 
                // dims. Each bank gets the IC dims.
                dims = dimsIC;
            }
            else {
                dims = addDimensionsForChassis(dims, getDeviceDimensions(mod));
            }
        }
    });

    // Add the end cap to the last bank.
    dims.width += 6;

    banks.push(dims);

    dims = getTotalChassisDimensionsFromBanks(banks);

    return [dims, banks];
}

const _createChassisPropsBankSizeHdr = (idxBank: number, slots: number): ChassisPropHeaderExch => {
    // Add a Bank header
    const bankHeader: ChassisPropHeaderExch = {
        headerName: `Bank #${idxBank + 1}`,
        items: [],
        headers: [],
    };

    bankHeader.items.push(
        {
            name: '# of Slots/Modules',
            value: `${slots}`
        }
    );

    return bankHeader;
}

const flexGetChassisProperties = (chassis: Chassis, redundant: boolean, bomItemExHdr?: BOMItemExch): ChassisPropertiesExch => {
    // Default
    const props: ChassisPropertiesExch = {
        deviceID: chassis.id,
        deviceName: chassis.name ?? '',
        headers: [],
    };

    // Add the Main Header
    addChassisPropMainHeader(props, chassis, redundant);

    // Check for banking. If we do NOT have an
    // Interconnect Cable, we do NOT have banks.
    const idxInterconnnect = chassis.modules.findIndex(mod => mod?.isInterconnect === true);
    if (idxInterconnnect === -1) {
        addChassisPropSizeHeader(props, chassis);
    }
    else {
        const sizeHeader: ChassisPropHeaderExch = {
            headerName: 'Chassis Size',
            items: [],
            headers: [],
        };

        // Create the simple headers for each bank.
        const nBank2Slots = chassis.modules.length - (idxInterconnnect + 1);
        const hdrBank1 = _createChassisPropsBankSizeHdr(0, idxInterconnnect);
        const hdrBank2 = _createChassisPropsBankSizeHdr(1, nBank2Slots);

        // If we have the Eng Data, which might NOT
        // be loaded during a config 'upgrade'...
        if (isEngDataLoaded(chassis.platform)) {
            const [, bankDims] = getChassisDimensions(chassis);
            if (bankDims.length === 2) {
                chassisPropsAddDimensionItems(hdrBank1, bankDims[0]);
                chassisPropsAddDimensionItems(hdrBank2, bankDims[1]);
            }
        }

        // Add the bank hdrs to the size hdr
        sizeHeader.headers.push(hdrBank1);
        sizeHeader.headers.push(hdrBank2);

        // Add the size hdr to the prop hdr
        props.headers.push(sizeHeader);
    }

    // Add the Parts Header.
    addChassisPropPartsHeader(props, chassis, bomItemExHdr);

    return props;
}


const getFlexImpl = (): GeneralImplSpec => {
    return {
        platform: PlatformFlex,
        imageScaleFactor: _flexImageScaleFactor,

        createChassis: snapCreateChassis,
        replaceChassisPowerSupply: undefined,
        configureChassis: snapConfigureChassis,
        addModuleAtSlot: snapAddModuleAtSlot,
        deleteModuleAtSlot: snapDeleteModuleAtSlot,
        getChassisDropStatus: snapGetChassisDropStatus,
        dropDragDeviceOnChassis: snapDropDragDeviceOnChassis,
        getChassisRenderer: snapGetChassisRenderer,
        getDefaultChassisName: snapGetDefaultChassisName,
        getActBtnInfo: snapGetActionBtnInfo,
        filterAvailableModules: snapFilterAvailableModules,
        getMaxNewModules: snapGetMaxNewModules,
        canExtendChassis: snapCanExtendChassis,
        canModuleBeAdded: snapCanModuleBeAdded,
        getSlotTypeRestriction: snapGetSlotTypeRestriction,
        getModuleSlotRestriction: snapGetModuleSlotRestriction,
        duplicateChassis: snapDuplicateChassis,
        updateChassisLayout: snapUpdateChassisLayout,
        addModulesToChassis: snapAddModulesToChassis,
        getChassisSizeAsDrawn: snapGetChassisSizeAsDrawn,
        getXSlotWidthFor: snapGetXSlotWidthFor,
        getDefaultXSlotWidth: snapGetDefaultXSlotWidth,
        getEmptySlotImage: flexGetEmptySlotImage,
        getDevicePowerTips: snapGetDevicePowerTips,
        getChassisRendInfo: snapGetChassisRendInfo,
        getChassisDimensions: flexGetChassisDimensions,
        getChassisProperties: flexGetChassisProperties
    };
}

export const RegisterFlexGeneralImpl = () => {
    const flexSnapDtls: SnapPlatformDetails = {
        imgScaleFactor: _flexImageScaleFactor,
        leftSlotStartSize: { width: 136, height: 129 },
        defaultXSlotWidth: 136,
        firstSlotRestricted: true,
        absMaxModules: 16,
        cableSplitAllowed: false,
        rightCapInfo: {
            imgSrc: imageRepository.getImageSource('5094_ECR.png'),
            width: _rightCapImgSize.width,
            height: _rightCapImgSize.height
        },
        getFPDMap: undefined, // Flex does not have FPDs
    }

    RegisterSnapClientDetails(PlatformFlex, flexSnapDtls);

    RegisterGeneralImpl(getFlexImpl());
}
