import { getApiUrl } from "platform-services";

const _legacySite = 'https://configurator.rockwellautomation.com/api/doc/';
const _useLegacySite = false;

class ImageRepository {
    private _api = 'DOCUMENT_HOST_LOCATION';
    private _site = '';
    private _siteEstablished = false;

    private establishSite() {
        if (!this._siteEstablished) {
            this._site = _useLegacySite
                ? _legacySite
                : getApiUrl(this._api);
            this._siteEstablished = true;
        }
    }

    getUrl() {
        if (!this._siteEstablished) {
            this.establishSite();
        }
        return this._site;
    }

    getImageSource(imgName: string | null | undefined): string {
        if (imgName && imgName.length) {
            if (imgName.indexOf('/') >= 0) {
                console.warn(
                    'WARNING: imgName has path characted in getImageSource: ' +
                    imgName
                );
                return imgName;
            }
            return this.getUrl() + imgName;
        }

        console.warn('WARNING: No imgName in getImageSource');
        return '';
    }
}

export const imageRepository = new ImageRepository();
