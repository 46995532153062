import { LocAndSize } from "../../../types/SizeAndPosTypes";
import { ChassisLayoutInfo } from "../../../types/ProjectTypes";
import { ImageInfo } from "../../common/CommonPlatformTypes";


//export interface ImageInfo {
//    imgSrc: string;
//    width: number;
//    height: number;
//}

export const MICROChassisImages = {
    emptySlotStd: 'EmptySlotStd.png',
    emptySlotXT: 'EmptySlotXT.png',
    grpSepStd: 'GrpSepStd.png',
    slotSepXT: 'SlotSepXT.png',
    rightCapStd: 'RightCapStd.png',
    rightCapXT: 'RightCapXT.png',
    none: ''
};

export const MICROEmptySlotImages = {
    standard: 'EEE',
    XT: 'XTE'
};

export const MICROGrpSepImages = {
    standard: 'GS',
    XT: ''
};

export const MICROSlotSepImages = {
    standard: '',
    XT: 'SSS'
};

export const MICRORightCapImages = {
    standard: 'RC',
    XT: 'RCXT'
};

export const MICROSizes = {
    height: 280,
    widthStdSlot: 70,
    widthXTSlot: 70,
    widthStdGrpSep: 20,
    widthXTGrpSep: 0,
    widthStdSlotSep: 0,
    widthXTSlotSep: 30,
    widthStdRightCap: 20,
    widthXTRightCap: 57
};


export interface MICROChassisTemplate {
    extendedTemp: boolean;
    conformal: boolean;
    totalSlots: number;
    slotGroups: number[];
    emptySlot: ImageInfo,
    slotSep: ImageInfo,
    grpSep: ImageInfo,
    rightCap: ImageInfo
}

export interface MICROChassisTemplateMicro {
    extendedTemp?: boolean;
    conformal?: boolean;
    totalSlots?: number;
    slotGroups?: number[];
    emptySlot?: ImageInfo,
    slotSep?: ImageInfo,
    grpSep?: ImageInfo,
    rightCap?: ImageInfo
}

export interface MICROLayoutInfo extends ChassisLayoutInfo {
    numSlots: number;
    emptySlotImgSrc: string;
    slotSepImgSrc: string;
    slotSepLocs: LocAndSize[];
    grpSepImgSrc: string;
    grpSepLocs: LocAndSize[];
    pluginSlot: number;
    ioExpansionSlot: number;
}
