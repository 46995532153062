import {
	SettingCode,
	SelectionType,
	IOEntryModeEnum,
	SettingOption,
	ProjectSetting,
	SettingGroupInfo
} from "../types/SettingsTypes";
import { ChassisProject } from "../types/ProjectTypes";
import {
	getIndustries,
	getIndustryDisplayStr,
	getCountries,
	getInstallLocDisplayStr
} from "../projInfo/LocationAndIndustry";
import { sanitizeNameString } from "../util/NameHelp";
import {
	getLocAttributeSetting,
	validateLocAttrInfo,
	updateGuidedSelection,
	getCurrentLocPointEntrySection
} from "../model/GuidedSelection";
import { DesignPageChildID, getDesignPageCompRenderInfo } from "./DesignPageComponentRenderInfo";
import { StartupDetails } from "../util/AppStartupHelp";
import { PlatformMicro } from "../platforms/PlatformConstants";
import { getLocAttrFromProject } from "../model/ChassisProject";

export const makeSettingGroup = (title: string): SettingGroupInfo => {
	return {
		title: title,
		settings: new Array<ProjectSetting>(),
		expandable: true,
		expanded: false,
	};
}

const makeIOEntryModeGroup = (project: ChassisProject): SettingGroupInfo => {
	const grp = makeSettingGroup('Configuration Details');
	grp.title = '';
	grp.expanded = false;
	grp.expandable = false;
	grp.settings.push({
		id: SettingCode.IOEntryMode,
		type: SelectionType.SwitchNoHeader,
		name: 'I/O Entry Mode',
		selectedOption: { id: project.config.IOEntryMode, display: project.config.IOEntryMode },
		units: '',
		options: Object.values(IOEntryModeEnum).map((opt) => { return { id: opt, display: opt } }),
		valid: true,
		defaultMessage: 'I/O Entry Mode',
		visible: true,
	});

	return grp;
}

const makeProjectDetailsGroup = (project: ChassisProject): SettingGroupInfo => {
	const grp = makeSettingGroup('Configuration  Details');

	const locAttrInfo = getLocAttrFromProject(project);
	const platform = locAttrInfo.platform;
	grp.expanded = true;
	grp.expandable = true;

	const cfgName = (project.config.projectName ? project.config.projectName : '');
	grp.settings.push({
		id: SettingCode.ConfigName,
		type: SelectionType.Edit,
		name: 'Configuration Name',
		selectedOption: { id: cfgName, display: cfgName },
		units: '',
		options: [],
		valid: (project.config.projectName ? true : false),
		defaultMessage: 'Configuration Name is required and follows the Studio 5000 naming conventions.',
		visible: true,
	});

	grp.settings.push({
		id: SettingCode.Location,
		type: SelectionType.RequiredCombo,
		name: 'Installation Location',
		selectedOption: { id: project.config.installLocID, display: getInstallLocDisplayStr(platform, project.config.installLocID) },
		units: '',
		options: getCountries(platform),
		valid: true,
		defaultMessage: 'Installation Location',
		msgWarningOrError: '', // project.settings.hardware.mapErrMessages.get(SettingCode.Location),
		visible: true,
		validateOnChange: false,
		updateIOSelections: true,
	});

	grp.settings.push({
		id: SettingCode.Industry,
		type: SelectionType.RequiredCombo,
		name: 'Industry',
		selectedOption: { id: project.config.industryID, display: getIndustryDisplayStr(platform, project.config.industryID) },
		units: '',
		options: getIndustries(platform),
		valid: true,
		defaultMessage: 'Industry',
		msgWarningOrError: '', // project.settings.hardware.mapErrMessages.get(SettingCode.Industry),
		visible: true,
		validateOnChange: false,
		updateIOSelections: true,
	});

	return grp;
}

export const getSettingState = (setting:ProjectSetting, startDtls?:StartupDetails):[tip?:string,disabled?:boolean] => {
	if(setting.id === SettingCode.IOEntryMode){
		const disabled = (startDtls?.platform === PlatformMicro);
		const tip = "Advance Setting";
		return [tip,disabled];
	}
	return [undefined,undefined];
}

export const getSettingGroups = (project: ChassisProject, sectionID: SettingCode): SettingGroupInfo[] => {
	switch (sectionID) {
		// We are only concerned with static attr/settings groups.
		// The attribute groups generated from Guided Selection are
		// are NOT retrieved through this mechanism!
		case SettingCode.AttrGrpIOSettings:
			return getIOEntryModeGroup(project);
		case SettingCode.AttrGrpProjectSettings:
			return getChassisSettingGroups(project);
		default:
			break;

	}
	const info = new Array<SettingGroupInfo>();
	return info;
}


export const getIOEntryModeGroup = (project: ChassisProject): SettingGroupInfo[] => {
	const info = new Array<SettingGroupInfo>();

	info.push(makeIOEntryModeGroup(project));

	return info;
}


export const getChassisSettingGroups = (project: ChassisProject): SettingGroupInfo[] => {
	const info = new Array<SettingGroupInfo>();

	info.push(makeProjectDetailsGroup(project));

	return info;
}


export const changeProjectSetting = (
	project: ChassisProject,
	setting: ProjectSetting,
	newVal: SettingOption | undefined
): boolean => {

	if (newVal) {
		newVal.display = newVal.display.trimStart();
	}

	const locInfo = getLocAttrFromProject(project);

	// Note: Get the ID here in a separate string var. For
	// some reason, within the switch statement, 'setting'
	// says it's possibly undefined in block-scope(?).
	const settingID = setting.id;

	switch (settingID) {

		case SettingCode.ConfigName:
			{
				// If we have a value...
				if (newVal) {

					// Sanitize it to our spec.
					// Note that we DO now allow inside spaces
					// in a configuration name.
					newVal.display =
						sanitizeNameString(newVal.display);
					project.config.projectName = newVal.display;
				}
				else {
					project.config.projectName = undefined;
				}
			}
			return true;

		case SettingCode.Location:
			{				
				if (newVal && newVal.id != locInfo.installLocationID) {
					// Mark the IO for refresh on next render. 
					getCurrentLocPointEntrySection(project).externalMaskChanged = true;

					project.config.installLocID = newVal.id;
					locInfo.installLocationID = newVal.id;
					updateGuidedSelection(
						locInfo,
						onUpdateGuidedSelectionComplete);
				}
			}
			return true;

		case SettingCode.Industry:
			{
				if (newVal && newVal.id != locInfo.industryID) {
					// Mark the IO for refresh on next render. 
					getCurrentLocPointEntrySection(project).externalMaskChanged = true;

					project.config.industryID = newVal.id;
					locInfo.industryID = newVal.id;
					updateGuidedSelection(
						locInfo,
						onUpdateGuidedSelectionComplete);
				}
			}
			return true;

		case SettingCode.IOEntryMode:
			{
				const val = (newVal ? newVal.display : '');
				const asEnum = val as IOEntryModeEnum;
				if (asEnum) {
					project.config.IOEntryMode = asEnum;
					locInfo.ioEntryMode = asEnum;
					return true;
				}
			}
			break;

		default:
			{
				// 2024.1.9 If we are here, we should have a new
				// Guided Selection Attribute changing.
				// Get the current Location Attribute Info.
				const setting = getLocAttributeSetting(locInfo, settingID);
				if (setting) {
					setting.selectedOption = (newVal ? newVal : { id: '', display: '' });

					// If this attribute requires validation after it changes...
					if (setting.validateOnChange) {
						validateLocAttrInfo(locInfo);
					}

					if (setting.updateIOSelections) {
						getCurrentLocPointEntrySection(project).externalMaskChanged = true;
						// Break here. We have succeeded?
						// Otherwise, we throw an exception/error?
						const renderIO = getDesignPageCompRenderInfo(DesignPageChildID.GuidedSelSection);
						if (renderIO)
							renderIO.setRenderCount(++renderIO.renderCount);
					}
				return true;
				}
			}

			// We could not find what this attribute is...
			throw new Error('Error: unexpected setting code in changeProjectSetting!');
	}

	return false;
}

const onUpdateGuidedSelectionComplete = (success: boolean) => {
	if (success) {
		const renderPage = getDesignPageCompRenderInfo(DesignPageChildID.DesignPage);
		if (renderPage) {
			renderPage.setRenderCount(++renderPage.renderCount);
		}
		// Re-render the GS attribute groups. 
		// const renderGS = getDesignPageCompRenderInfo(DesignPageChildID.GuidedSelSection);
		// if (renderGS)
		// 	renderGS.setRenderCount(++renderGS.renderCount);

		// The I/O should have been marked for refresh
		// on next render - re-render the point entry area.
		// const renderIO = getDesignPageCompRenderInfo(DesignPageChildID.PointEntrySection);
		// if (renderIO)
		// 	renderIO.setRenderCount(++renderIO.renderCount);
	}
}
