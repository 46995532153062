import React, { useCallback, useRef, useState } from 'react';
import '../styles/AppLayout.scss';
import {
    Chassis,
    MicroChassis,
    ChassisModule,
    ChassisProject,
    DeviceType,
    ChassisPowerSupply,
    SelectableDevice
} from '../types/ProjectTypes';
import { ProjectViewType } from '../types/ViewTypes'
import {
    addSlotFillers,
    deleteChassis,
    projectHasAnyEmptySlots,
    projectHasAnySlotFillers,
    removeAllSlotFillers,
    updateAllChassis,
    updateContentExtent
} from '../model/ChassisProject';
import { LayoutModeType } from '../util/LayoutModeHelp';
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import {
    canRedo,
    canUndo,
    chassisChanging,
    contentChanging,
    redoLastRackChange,
    suspendUndoSnapshots,
    undoLastRackChange
} from '../util/UndoRedo';
import {
    AutoScaleFitType,
    autoScaleToContent,
    ChassisLayoutKey,
    zoomIn,
    zoomOut
} from '../util/LayoutHelp';
import ChassisSelLayoutPanel from './ChassisSelLayoutPanel';
import ChassisSelTreePanel from './ChassisSelTreePanel';
import { ToolbarAction } from '../toolbar/ToolbarActions';
import { confirmAndCallBack, displayUnderConstructionMsg } from '../util/MessageHelp';
import { getConfirmMsgChassisDelete } from '../util/Messages';
import { ModalStatus } from '../modals/ModalHelp';
import { unexpectedError } from '../util/ErrorHelp';
import { AllowNonAvailablePlatformTemplatesToLoad, AllowTestPlatformLoads, isTextEditInProgress, LogRender } from '../types/Globals';
import { displayProjectLogMessages } from '../projStatus/ProjectStatusDisplay';
import { configCanvasSettings } from '../components/CanvasSettings';
import LayoutModeDispInfo from '../components/LayoutModeDispInfo';
import { ActBtnInfo, LayoutActionType } from '../types/LayoutActions';
import { logger } from '../util/Logger';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setCurrentView } from '../redux/slices/ViewSlice';
import { startupDetails } from '../redux/slices/StartupInfoSlice';
import { useLayoutMode, useSelectedChassis, useSelectedDevice } from '../context/SelectionInfoContext';
import { ReorderChassis } from '../components/OrganizeChassis';
import {
    addModuleAtSlot,
    canDeletePowerSupply,
    canModuleBeAdded,
    configureChassis,
    deleteModule,
    deleteModuleAtSlot,
    deletePowerSupply,
    duplicateChassis,
    updateChassis
} from '../implementation/ImplGeneral';
import { selectPlatform } from '../platforms/PlatformSelector';
import { getLocationAttrInfo } from '../model/LocAttributeInfo';
import {  saveConfigurationAsync } from '../userProject/ProjectSaveAndRestore';
import { isConfigModified } from '../userProject/UserProjectHelp';
import { flexHAToggleDuplex } from '../platforms/flexHA/model/FlexHAChassis';
import { PlatformFlexHA } from '../platforms/PlatformConstants';
import { _getLocForMod, defaultWidthBuDeletion} from '../platforms/micro/model/MicroGeneralImpl';


interface Props {
    project: ChassisProject;
    contentChanged: () => void;
    onFirstVScroll: () => void;
}

const ChassisSelection = (props: Props) => {

    const dispatch = useAppDispatch();
    const startupDtls = useAppSelector(startupDetails);

    // Render control
    const [/*localRndCnt*/, setLocalRndCnt] = useState(0);
    const chasSelLastCnt = useRef(0);
    const forceLocalRender = useCallback(() => {
        chasSelLastCnt.current += 1;
        setLocalRndCnt(chasSelLastCnt.current);
    }, []);


    const { layoutMode, setLayoutMode } = useLayoutMode();
    const { selectedChassis, selectChassis } = useSelectedChassis();
    const { selectedDevice, selectDevice } = useSelectedDevice();

    const theProject = props.project;
    const contentChangedFunc = props.contentChanged;
    const requestModeAction = useCallback((actBtnInfo: ActBtnInfo) => {

        // Look at action being requested.
        switch (actBtnInfo.action) {

            // If its a quick-copy mode copy...
            case LayoutActionType.ModeCopy:

                // Sanity check that we're IN copy mode. If so...
                if (layoutMode.type === LayoutModeType.Copy) {

                    // Then we also SHOULD have been provided the
                    // target catalog number that we're supposed
                    // to use for the copy drop. If so...
                    if (actBtnInfo.copyCat && (actBtnInfo.copyCat.length > 0)) {

                        // Add that using the chassis and slot provided
                        // in our actBtnInfo argument.
                        addModuleAtSlot(actBtnInfo.chassis, actBtnInfo.copyCat, actBtnInfo.slot, true, true);

                        // Our addModule call just above DID end up
                        // updating content extent. However, that function
                        // and its helpers don't normally include the possibility
                        // for x-slot chassis extensions UNLESS the chassis is
                        // selected or marked as a .dragTarget, neither of which
                        // can be true in Copy mode. To get around that, we'll
                        // make our OWN call to the update function, specifying that
                        // we ARE in copy mode, something that can't otherwise be
                        // detected from inside the function.
                        if (actBtnInfo.chassis.parent) {
                            updateContentExtent(actBtnInfo.chassis.parent, true);
                        }

                        // Notify our parent that we changed something.
                        contentChangedFunc();
                    }
                    else {
                        throw new Error('Error: Missing copyCat!');
                    }
                    break;
                }
                else {
                    throw new Error('Error: requestModeAction copy/mode mismatch!');
                }

            // If a quick-delete mode request
            case LayoutActionType.ModeDelete:

                // Sanity check that we're actually IN delete mode. If so...
                if (layoutMode.type === LayoutModeType.Delete) {

                    // Call a helper to delete the specified module.
                    deleteModuleAtSlot(actBtnInfo.chassis, actBtnInfo.slot);

                    // Notify our parent that we changed something.
                    contentChangedFunc();
                    break;
                }
                else {
                    throw new Error('Error: requestModeAction delete/mode mismatch!');
                }

            case LayoutActionType.MakeDuplex:
            case LayoutActionType.MakeSimplex:
                // These actions are Flex HA Exclusively.
                if (actBtnInfo.chassis.platform === PlatformFlexHA) {
                    flexHAToggleDuplex(actBtnInfo);
                    contentChangedFunc();
                }
                break;

            default:
                throw new Error('Unexpected action in requestModeAction!');
        }
    }, [contentChangedFunc, layoutMode]);

    const onSave = useCallback(async () => {
        if (isConfigModified()) {
            const userProjGuid = (startupDtls?.projectGuid)
                ? startupDtls.projectGuid
                : '';
            await saveConfigurationAsync(userProjGuid, theProject);          
        }
    },
    [theProject,
     startupDtls 
    ]
)


    const redoLastUndo = useCallback(() => {
        // If a redo is possible...
        if (canRedo()) {
            // Call our helper to do it for us, which will
            // return to us the chassis and/or device that
            // was selected when the snap was saved.
            const [selChas, selDev] = redoLastRackChange(theProject);

            // If either selection is differs from what
            // our sections refs contain...
            if ((selChas !== selectedChassis) ||
                (selDev !== selectedDevice)) {
                // Call both selectors.
                selectChassis(selChas);
                selectDevice(selDev);
            }

            // Notify our parent that our
            // project content has changed. 
            contentChangedFunc();
        }
    }, [theProject,
        contentChangedFunc,
        selectedChassis,
        selectedDevice,
        selectChassis,
        selectDevice ]);

    const undoLastChange = useCallback(() => {
        // If an undo is possible...
        if (canUndo()) {
            // Call our helper to do it for us, which will
            // return to us the chassis and/or device that
            // was selected when the snap was saved.
            const [selChas, selDev] = undoLastRackChange(theProject);

            // If either selection is differs from what
            // our sections refs contain...
            if ((selChas !== selectedChassis) ||
                (selDev !== selectedDevice)) {
                // Call both selectors.                 
                selectChassis(selChas);
                selectDevice(selDev);
            }

            // Notify our parent that our
            // project content has changed. 
            contentChangedFunc();
        }
    }, [theProject,
        contentChangedFunc,
        selectedChassis,
        selectedDevice,
        selectChassis,
        selectDevice]);


    interface DelModuleData {
        module: ChassisModule;
    }

    interface DeletePSUData {
        psu: ChassisPowerSupply;
    }

    interface DelModuleDataMicro {
        module?: ChassisModule | undefined;
    }
    const deleteBaseUnitCallback= useCallback((status: number, data?: object) => {

        // We're being notified that the results from
        // our confirmAndCallBack call below are in.
        // If the user confirmed the chassis delete...
        if (status === ModalStatus.Confirmed) {

            // Get the data that should have been sent
            // back to us in the form we expect.
            const delData = data as DelModuleDataMicro;
            const module = delData.module;
            const chassis: MicroChassis | undefined = module && module.parent;
            if (module && chassis ) {
                chassisChanging(chassis);
          
                const widthX = defaultWidthBuDeletion;
                let lastX = widthX;
                chassis.pluginModules = []
                chassis.layout.slotLocs = []
              
                chassis.modules = chassis.modules.filter(mod=> mod && mod?.deviceType !== DeviceType.PlugIn )
            
                const isExpasnionModule = chassis.modules.filter(mod=> mod && mod?.deviceType !== DeviceType.PlugIn)
               
                for (let slotIdx = 0; slotIdx < isExpasnionModule.length; slotIdx++) {
                    const nextLoc = _getLocForMod(isExpasnionModule[slotIdx], lastX);
					chassis.layout.slotLocs[slotIdx]= nextLoc;
                    lastX += nextLoc.width;
                }

                chassis.layout.size.width = lastX;
        
              
                
                // // chassis bu removal flag by image deletion
                // module.imgSrc = '';
                chassis.ps = undefined;
                chassis.bu = undefined;
                chassis.buCatnumber = undefined;
                chassis.isIoSeperate = false
                chassis.totalModules =  9;
                updateChassis(chassis)
                selectDevice(undefined);
                contentChangedFunc();
      
            }

            else {
                unexpectedError('deleteBaseUnitCallback missing expected data!', true, false);
            }
        }
    }, [contentChangedFunc, selectDevice]);

    const deleteModuleCallback = useCallback((status: number, data?: object) => {

        // We're being notified that the results from
        // our confirmAndCallBack call below are in.
        // If the user confirmed the chassis delete...
        if (status === ModalStatus.Confirmed) {

            // Get the data that should have been sent
            // back to us in the form we expect.
            const delData = data as DelModuleData;
            const module = delData.module;

            //if (module.parent && (module.slotIdx >= 0)) {
            if (module.parent) {
                const chassis = module.parent;

                // Delete the module using the saved info.
                // If successful, notify our parent that
                // project content was changed.
                if (deleteModule(chassis, module)) {
                    selectDevice(undefined);
                    contentChangedFunc();
                }
            }
            else {
                unexpectedError('deleteModuleCallback missing expected data!', true, false);
            }
        }
    }, [contentChangedFunc, selectDevice]);

    const deletePowerSupplyCallback = useCallback((status: number, data?: object) => {
        // If confirmed...
        if (status === ModalStatus.Confirmed) {
            const delData = data as DeletePSUData;
            if (delData && delData.psu) {
                if (canDeletePowerSupply(delData.psu)) {
                    deletePowerSupply(delData.psu);
                    contentChangedFunc();
                }
                else
                    unexpectedError('Cannot delete a required Power Supply!', true, false);
            }
            else {
                unexpectedError('deletePowerSupplyCallback missing expected data!', true, false);
            }
        }
    }, [contentChangedFunc]);

    const doBaseUnitDelete= useCallback((module: ChassisModule) => {
        // Tell the user what's going to happen.
        const chassis: MicroChassis| undefined = module.parent;
        const pluginLength = chassis?.pluginModules?.length ? chassis?.pluginModules?.length : 0;
        let pluginFlag = false;
        const msg = 'Delete Controller (' +
            module.catNo +
            ')?  You can add a new Controller to replace via + on this location.';
            const msg1 = 'Deleting this Controller will also delete the plugin-modules associated with the Controller.';
            let result =  '';
            let test =''
        for(let i=0; i<pluginLength; i++){
            if( chassis?.modules[i]){
                pluginFlag = true;
                const count = i+1
                test += '{slot ' + count +' }'+ ' : { ' + chassis?.modules[i]?.catNo + ' } \n'; 
            }      
        }
        // Set up everything we need to know when
        // we get the callback.
        const callbackData: DelModuleData = {
            module: module
        };
        result = pluginFlag ?  msg + " \n \n " + msg1 +" \n ": "\n \n" + msg + " \n \n " 
        // Hand off to the confirmer.
        confirmAndCallBack(result+test, deleteBaseUnitCallback, callbackData,
            'Delete Controller?');
    }, [deleteBaseUnitCallback]);

    const doModuleDelete = useCallback((module: ChassisModule) => {
        // Tell the user what's going to happen.
        const msg = 'Delete module (' +
            module.catNo +
            ')?  You can add a new module to replace via + on this location.';

        // Set up everything we need to know when
        // we get the callback.
        const callbackData: DelModuleData = {
            module: module
        };

        // Hand off to the confirmer.
        confirmAndCallBack(msg, deleteModuleCallback, callbackData,
            'Delete Module?');
    }, [deleteModuleCallback]);

    const doPowerSupplyDelete = useCallback((selectedDevice: SelectableDevice) => {
        // Construct and execute the confirmation dialog.
        const callbackData: DeletePSUData = { psu: selectedDevice as ChassisPowerSupply };
        const msg = `Delete power supply (${selectedDevice.catNo})?`;
        confirmAndCallBack(msg, deletePowerSupplyCallback, callbackData,
            'Delete Power Supply?');
    }, [deletePowerSupplyCallback]);

    interface DelChassisData {
        chassis: Chassis;
    }

    const deleteChassisCallback = useCallback((status: number, data?: object) => {

        // We're being notified that the results from
        // our confirmAndCallBack call below are in.
        // If the user confirmed the chassis delete...
        if (status === ModalStatus.Confirmed) {

            // Get the data that should have been sent
            // back to us in the form we expect.
            const delData = data as DelChassisData;

            // If we can, and we have a chassis...
            if (delData && delData.chassis) {
                // BEFORE making any actual change, call our
                // helper to handle any related undo/redo
                // work for us.
                chassisChanging(delData.chassis);

                // Specify that NO chassis should
                // be currently selected. Ours
                // should have been the one selected
                // at this point.
                selectChassis(undefined);

                // Perform the actual delete.
                deleteChassis(delData.chassis);

                // Then notify our parent that 
                // we changed project content.
                contentChangedFunc();
            }
            else {
                unexpectedError('deleteChassisCallback missing expected data!', true, false);
            }
        }
    }, [contentChangedFunc, selectChassis]);

    const doChassisDelete = useCallback((chassis: Chassis) => {
        // Get message text we should 
        // use for user confirmation.
        const msg = getConfirmMsgChassisDelete(chassis);

        // Store the chassis reference in a data object.
        const cbData: DelChassisData = {
            chassis: chassis
        };

        // Ask for confirmation. Either way, our callback
        // function will get called with the user's decision,
        // confirm or cancel, along with our data object.
        confirmAndCallBack(msg, deleteChassisCallback, cbData, 'Delete Chassis?');
    }, [deleteChassisCallback]);

    const deleteSelection = useCallback(() => {
        if (selectedChassis) {
            if (selectedDevice) {
                if ((selectedDevice.deviceType !== DeviceType.PS && selectedDevice.deviceType !== DeviceType.BU)) {
                    doModuleDelete(selectedDevice as ChassisModule);
                    return;
                }
                //Base Unit selection 
                else if (selectedDevice.deviceType === DeviceType.BU) {
                    doBaseUnitDelete(selectedDevice as ChassisModule);
                    return;
                }
                else if (selectedDevice.deviceType === DeviceType.PS && canDeletePowerSupply(selectedDevice)) {
                    doPowerSupplyDelete(selectedDevice);
                    return;
                }
            }

            doChassisDelete(selectedChassis);
        }
    }, [doChassisDelete, doModuleDelete, selectedChassis, selectedDevice, doBaseUnitDelete, doPowerSupplyDelete]);

    const dupSelectedChassis = useCallback((chassis: Chassis) => {
        if (chassis.parent) {
            // Make our OWN call to contentChanging
            // to take a pre-dup undo snapshot.
            contentChanging(chassis.parent);

            // Then suspend those, so that we DON'T
            // take any more DURING the dup process.
            suspendUndoSnapshots(true);

            // Perform the duplication, which we
            // expect should ALWAYS work.
            const dup = duplicateChassis(chassis, -1);

            // UN-suspend shapshots.
            suspendUndoSnapshots(false);

            // If we got a dup, inform our
            // parent that we changed content.
            if (dup) {
                // Give the new chassis a chance to do
                // anything it may have postponed due to
                // undo snapshots being suspended. This
                // will also call updateRackLayout for us.
                updateChassis(dup);

                // Then notify our parent that
                // content has changed.
                contentChangedFunc();
            }
        }
    }, [contentChangedFunc]);

    const dupSelectedModule = useCallback((chassis: Chassis, module: ChassisModule) => {

        // Check to see if a copy of the given
        // module would fit into the specified chassis.
        const slotLoc = canModuleBeAdded(module, chassis);

        // If our chassis has a parent, and if we were
        // given a valid insert position for a copy...
        if (chassis.parent && (slotLoc >= 0)) {

            // Add a copy of the module at the specified slot.
            // Note: This call will take an Undo snapshot for us.
            addModuleAtSlot(chassis, module.catNo, slotLoc, true,true);

            // Inform our parent that we changed content.
            contentChangedFunc();
        }
    }, [contentChangedFunc]);


    const doAddChassis = useCallback(() => {

        const onPlatformSelected = (platform: string) => {
            configureChassis(platform, theProject,
                selectChassis, selectDevice, contentChangedFunc);
        }

        const availPlatforms = new Array<string>();
        if (startupDtls) {
            startupDtls.availPlatforms.forEach(p => {
                availPlatforms.push(p);
            });
        }

        // If we are allowing foreign platforms to be imported,
        // we could have a platform that is NOT in availablePlatforms.
        if (AllowNonAvailablePlatformTemplatesToLoad) {
            const locAttrInfo = getLocationAttrInfo(props.project.config.currLocAttrID);
            const arrPlatforms = [...availPlatforms];
            if (locAttrInfo && availPlatforms.some(x => x === locAttrInfo.platform) === false) {
                // If we are NOT allowing multi-platform support...
                if (!AllowTestPlatformLoads) {
                    // Clear the platform array so that ONLY
                    // the locAttrInfo platform is present.
                    arrPlatforms.length = 0;
                }

                arrPlatforms.push(locAttrInfo.platform);
            }

            selectPlatform(arrPlatforms, onPlatformSelected);
        }
        else {
            // We should have a valid platform.
            selectPlatform(availPlatforms, onPlatformSelected);
        }


    }, [theProject,
        selectChassis,
        selectDevice,
        contentChangedFunc,
        startupDtls,
        props.project.config.currLocAttrID]);


    const onTBAction = useCallback((action: string) => {
        let snapshotsWereSuspended = false;
        switch (action) {
            case ToolbarAction.SETTINGS:
                configCanvasSettings(theProject, forceLocalRender);
                break;

            case ToolbarAction.SAVE:
                onSave();
                break;

            case ToolbarAction.UNDO:
                undoLastChange();
                break;

            case ToolbarAction.REDO:
                redoLastUndo();
                break;

            case ToolbarAction.ZOOMIN:
                if (zoomIn(ChassisLayoutKey)) {
                    forceLocalRender();
                }
                break;

            case ToolbarAction.ZOOMOUT:
                if (zoomOut(ChassisLayoutKey)) {
                    forceLocalRender();
                }
                break;

            case ToolbarAction.RESIZE_W:
                autoScaleToContent(ChassisLayoutKey, theProject.content.totalExtent,
                    AutoScaleFitType.Width);
                forceLocalRender();
                break;

            case ToolbarAction.RESIZE_A:
                autoScaleToContent(ChassisLayoutKey, theProject.content.totalExtent,
                    AutoScaleFitType.All);
                forceLocalRender();
                break;

            case ToolbarAction.DELETE:
                deleteSelection();
                break;

            case ToolbarAction.COPY:
                if (selectedChassis) {
                    if (selectedDevice) {
                        const mod = selectedDevice as ChassisModule;
                        dupSelectedModule(selectedChassis, mod);
                    }
                    else {
                        dupSelectedChassis(selectedChassis);
                    }
                }
                break;

            case ToolbarAction.ADDCHASSIS:
                // TO DO: Need to get platform from project
                // and NOT specifiy PlatformCLX directly here!
                //configureChassis(PlatformCLX, theProject,
                //    selectChassis, selectDevice, contentChangedFunc);
                doAddChassis()
                break;

            case ToolbarAction.COPYMODE:
                setLayoutMode(LayoutModeType.Copy);
                updateContentExtent(theProject.content, true);
                break;

            case ToolbarAction.DELMODE:
                setLayoutMode(LayoutModeType.Delete);
                break;

            case ToolbarAction.ADDSLOTFILLERS:
                if (projectHasAnyEmptySlots(theProject)) {
                    contentChanging(theProject.content);
                    snapshotsWereSuspended = suspendUndoSnapshots(true);
                    addSlotFillers(theProject);
                    suspendUndoSnapshots(snapshotsWereSuspended);
                    updateAllChassis(theProject.content);
                    contentChangedFunc();
                }
                break;

            case ToolbarAction.REMOVESLOTFILLERS:
                if (projectHasAnySlotFillers(theProject)) {
                    contentChanging(theProject.content);
                    snapshotsWereSuspended = suspendUndoSnapshots(true);
                    removeAllSlotFillers(theProject);
                    suspendUndoSnapshots(snapshotsWereSuspended);
                    updateAllChassis(theProject.content);
                    contentChangedFunc();
                }
                break;

            case ToolbarAction.NORMALMODE:
                setLayoutMode(LayoutModeType.Normal);
                updateContentExtent(theProject.content, false);
                break;

            case ToolbarAction.ORGANIZECHASSIS:
                //Organizechassissetting()
                ReorderChassis(theProject, selectChassis, contentChangedFunc)
                break;

            /*   case ToolbarAction.EDITALLCHASSIS:
                  displayUnderConstructionMsg(getFutureFeatureDesc(action));
                  break;
   */

            case ToolbarAction.ISSUES:
                displayProjectLogMessages(theProject.content.statusLog, theProject, contentChangedFunc);
                break;

            case ToolbarAction.DESIGN:
                // If we are going back to design, make 
                // sure that our layout mode is set to
                // normal and unselect everything.
                setLayoutMode(LayoutModeType.Normal);
                selectChassis(undefined);
                selectDevice(undefined);
                dispatch(setCurrentView(ProjectViewType.Design));
                break;


            default:
                displayUnderConstructionMsg(action + ' action not implemented.');
                break;

        }
    }, [contentChangedFunc,
        deleteSelection,
        dispatch,
        dupSelectedChassis,
        dupSelectedModule,
        forceLocalRender,
        theProject,
        redoLastUndo,
        selectChassis,
        selectDevice,
        selectedChassis,
        selectedDevice,
        setLayoutMode,
        undoLastChange,
        doAddChassis,
        onSave]);

    const onKeyDown = (e: React.KeyboardEvent) => {
        // Don't do anything if a text edit is
        // in progress. Ex: Chassis name is
        // being edited in our project tree.
        if (isTextEditInProgress()) {
            return;
        }

        let handled = false;

        // Ignore the key if it is the
        // Ctrl key itself. If not...
        if (e.key !== 'Control') {

            // Then, if the Ctlr key is ALSO down...
            if (e.ctrlKey) {
                switch (e.key) {
                    case 'Y': // Redo
                    case 'y':
                        redoLastUndo();
                        handled = true;
                        break;

                    case 'Z': // Undo
                    case 'z':
                        undoLastChange();
                        handled = true;
                        break;

                    // Ignore codes that we only care about
                    // at the layout stage level. We'll only get
                    // these if the Ctrl-_ happened OUTSIDE of the
                    // stage, and we don't want to do anything special
                    // with those anyway.
                    case 'P': // Ctrl-p
                    case 'p':
                    case 'X': // Ctlr-x
                    case 'x':
                        break;

                    // Log any Ctrl-_ we get other than specifically
                    // dealt with already above.
                    default:
                        logger.log('Ctrl+ (key ' + e.key + ') pressed!');
                        break;
                }
            }
            else {
                // Ctrl key NOT down.
                // If neither the Alt or Shift keys
                // are down...
                if (!e.altKey && !e.shiftKey) {

                    // Then handle keys we're intested in.
                    switch (e.key) {

                        // Delete selection if key is the
                        // backspace or delete key. Note
                        // that the delete function called
                        // here DOES pre-check and get
                        // user confirmation.
                        case 'Backspace':   // Backspace
                        case 'Delete':  // Delete key
                            deleteSelection();
                            handled = true;
                            break;

                        default:
                            break;
                    }
                }
            }
        }


        if (handled) {
            e.preventDefault();
            e.stopPropagation();
        }
    };


    if (LogRender.Views) {
        logger.logRender('Render ChassisSelection');
    }

    return (
        <div
            className='chassis-selection'
            onKeyDown={onKeyDown}
            tabIndex={-1} /*tabIndex needed to get onKeyDown */
        >
            <Allotment separator={true}>
                <Allotment.Pane preferredSize={'20%'} minSize={200}>
                    <ChassisSelTreePanel
                        project={props.project}
                        onTBAction={onTBAction}
                        contentChanged={contentChangedFunc}
                    />
                </Allotment.Pane>
                <Allotment.Pane preferredSize={'80%'} minSize={400}>
                    <ChassisSelLayoutPanel
                        project={props.project}
                        requestModeAction={requestModeAction}
                        onTBAction={onTBAction}
                        contentChanged={contentChangedFunc}
                        onFirstVScroll={props.onFirstVScroll}
                    />
                </Allotment.Pane>
            </Allotment>
            <LayoutModeDispInfo
                layoutMode={layoutMode}
            />
        </div>
    );
}

export default ChassisSelection;

