import {
    PlatformCLX,
    PlatformCpLX,
    PlatformFlex,
    PlatformFlexHA,
    PlatformMicro,
} from "../platforms/PlatformConstants";
import FEATURES from "../util/FeatureFlags";

export const UseStrictModeForDev = true;

export const DEV_DEBUG = process.env.NODE_ENV === "development";
export const NOT_DEV_DEBUG = !DEV_DEBUG;

export const RunningStrictMode = DEV_DEBUG && UseStrictModeForDev;

export const SkipUnloadDlgInDevMode = DEV_DEBUG && false;

export const HideRAHeaderOnFirstVScroll = false;

export const DebounceLayoutScrolling = false;
export const DebounceScrollMs = 20;

const _skipRaiseDataDebug = false;
const _skipRaiseDataProd = false;

export const SkipRaiseData = DEV_DEBUG
    ? _skipRaiseDataDebug
    : _skipRaiseDataProd;

export const StartFloatingDtlsMinimized = true;

export const ProjTreeChassisInitExpanded = DEV_DEBUG ? true : true;

const _devPointEntryMaxVal = 3000;
const _prodPointEntryMaxVal = 999;
export const PointEntryMaxVal = DEV_DEBUG
    ? _devPointEntryMaxVal
    : _prodPointEntryMaxVal;

export const DebugAids = {
    ShowContentExtentRct: DEV_DEBUG && false,
    ShowStageBackground: DEV_DEBUG && false,
};

export const LogRender = {
    ClearConsoleOnGenerate: DEV_DEBUG && false,
    Views: DEV_DEBUG && false,
    Layout: DEV_DEBUG && false,
    ProjTree: DEV_DEBUG && false,
    Details: DEV_DEBUG && false,
    ActButtons: DEV_DEBUG && false,
    SummaryComps: DEV_DEBUG && false,
    SelectionAPIs: DEV_DEBUG && false,
    Templates: DEV_DEBUG && false,
    DesignPage: DEV_DEBUG && false,
};

export const LogImplDefaults = DEV_DEBUG && true;

export const ShowDeviceTree = false;

export const UseSmartPowerDsply = true;

export const DisableIncomptableChassisSwaps = false;

export const IncludeOldStyleMsgPnlInChassisConfig = DEV_DEBUG && false;

export const ShowFloatingPerformanceInfo = DEV_DEBUG && false;

// Globally accessible vars/functions for coordination, etc.
let _textEditInProgress = false;

export const setTextEditInProgress = (inProg: boolean) => {
    _textEditInProgress = inProg;
};

export const isTextEditInProgress = (): boolean => {
    return _textEditInProgress;
};

// Start - DEBUG Performance Help

const _dfltDebugPrfMult = 1.0;

export const PerfMultipliers = {
    CtlrMaxEnetNodes: _dfltDebugPrfMult,
    CtlrMaxCIPConns: _dfltDebugPrfMult,
    CtlrMemory: _dfltDebugPrfMult,
    CommMaxCIPConns: _dfltDebugPrfMult,
    CommMaxIOPPS: _dfltDebugPrfMult,
};

const _tweakPerfSupport = false;
export const ApplyDebugPerfMultipliers = DEV_DEBUG && _tweakPerfSupport;

export const UseL7AsDfltController = DEV_DEBUG && false;

export const IncludeNetBandwidthInBytes = DEV_DEBUG && false;

export const ValPrec = {
    G: DEV_DEBUG ? 1 : 1,
    M: DEV_DEBUG ? 1 : 1,
    K5: DEV_DEBUG ? 0 : 0,
    K1: DEV_DEBUG ? 1 : 1,
};

// End - DEBUG Performance Help

// 2024.3.7
export const AllowNonAvailablePlatformTemplatesToLoad = false;

// 2024.1.20 Guided and Product Selection.
export const UseDefaultGuidedSelection = false && DEV_DEBUG; // Set to (false && DEV_DEBUG) to use API calls in DEV_DEBUG.
export const UseDefaultProductSelection = false && DEV_DEBUG;
export const UseDefaultCountries = false && DEV_DEBUG;
export const UseDefaultIndustries = false && DEV_DEBUG;

// Engineering Data.
export const UseDefaultEngineeringData = false && DEV_DEBUG;

export const AllowTestPlatformLoads = false && DEV_DEBUG;

export const ExtraTestPlatforms: string[] = [
    PlatformCLX,
    PlatformCpLX,
    PlatformFlex,
    PlatformFlexHA,
    PlatformMicro,
];

export const getFeatureFlag=(flag:string|boolean)=>{
    if(flag === "true" || flag === true ){
        return true;
    }
    return false;
}

export const ToastStartupProgress = true;

export const Enable5015FlexHAPlatform = getFeatureFlag(window.featureFlags[FEATURES.PI26_BP11_ENABLE_FELXHA5000_FAMILY]);

export const Enable2080MicroPlatform = getFeatureFlag(window.featureFlags[FEATURES.PI25_BP19_ENABLE_MICRO800_FAMILY]);

export const UseOldHeaderComponents = false;

export const MaxLocalSnapshotAgeDays = DEV_DEBUG ? 1 : 5;

//export const Use5015TransparentImgs = false;

export const Show5015BanksVertically = true;
export const Start5015BankQty = DEV_DEBUG ? 1 : 1;
