
export interface ProdDimensionData {
    Height?: string;
    Width?: string;
    Depth?: string;
}

export interface Dimensions {
    height: number;
    width: number;
    depth: number;
}


export const getEmptyDimensions = (): Dimensions => {
    return {
        height: 0,
        width: 0,
        depth: 0
    };
}

export const addDimensionsForChassis = (a: Dimensions, b: Dimensions): Dimensions => {
    // Start our return dims as 'a'.
    const dims = { ...a };

    // Add b's width.
    dims.width += b.width;

    // For height and depth, take the
    // greater value of a or b.
    if (b.height > dims.height)
        dims.height = b.height;
    if (b.depth > dims.depth)
        dims.depth = b.depth;

    return dims;
}