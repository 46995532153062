import { getChassisProperties, getChassisRendInfo } from "../implementation/ImplGeneral";
import { getRedundantChassisNameAndID } from "../model/ChassisProject";
import {
    PlatformCLX,
    PlatformCpLX,
    PlatformFlex,
    PlatformFlexHA,
    PlatformMicro
} from "../platforms/PlatformConstants";
import { Chassis } from "../types/ProjectTypes";
import { LocAndSize, Size } from "../types/SizeAndPosTypes";
import { BOMItemExch, ChassisPropertiesExch } from "../userProject/UserProjectTypes";


export interface RendBorder {
    stroke: string;
    strokeWidth?: number;
    strokeScaleEnabled?: boolean;
}

export interface RendPortion {
    loc: LocAndSize;
    image: string;
    border?: RendBorder;
}

export interface RendInfo {
    size: Size;
    simpleImage?: string;
    els?: RendPortion[];
}

export interface DevMetadata {
    rendInfo?: RendInfo[];
    ctlrCommsSpt?: unknown;
    remCommsReqd?: unknown;
    chassisDetails?: ChassisPropertiesExch;
}

export interface ChassisFmtForMdTp {
    guid: string;
    devMetadata: DevMetadata;
}

export const getSysDsgDefChassisImg = (platform: string): string | undefined => {
    switch (platform) {
        case PlatformCLX:
            return '1756DefChassis.png';
        case PlatformCpLX:
            return '5069DefChassis.png';
        case PlatformFlex:
            return '5094DefChassis.png';
        case PlatformFlexHA:
            return '5015DefChassis.png';
        case PlatformMicro:
            return '2080DefChassis.png';
        default:
            break;
    }

    return undefined;
}

export const getChassisMetadata = (chassis: Chassis, red: boolean, chassisBomItExHdr?: BOMItemExch): string => {
    const devMd: DevMetadata = {
        rendInfo: getChassisRendInfo(chassis),
    }

    if (chassis.ctlrCommsSpt) {
        devMd.ctlrCommsSpt = { ...chassis.ctlrCommsSpt };
    }

    if (chassis.remCommsReqd) {
        devMd.remCommsReqd = { ...chassis.remCommsReqd };
    }

    devMd.chassisDetails = getChassisProperties(chassis, red, chassisBomItExHdr);

    // Call a common function to get 
    // the redundant chassis ID.
    //const chasId = red
    //    ? chassis.id + '(R)'
    //    : chassis.id;
    let chasId = chassis.id;
    if (red)
        [, chasId] = getRedundantChassisNameAndID(chassis);

    const dataForTp: ChassisFmtForMdTp = {
        guid: chasId,
        devMetadata: devMd
    }

    const tp = JSON.stringify(dataForTp);
    return tp;
}
